<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url5" :preview-src-list="srcList5">
                    </el-image>
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">分布式光纤感温装置</div>
                    <div class="detaildesr">
                        分布式光纤感温装置是我司根据当前行业现状，自主研发的基于定位原理的分布式测温产品。分布式光纤温度传感器，通常用在检测空间温度分布的系统，
                        具有监测距离长、全域分布无盲点、定位精确、无源防爆、抗干扰、对环境要求低、使用寿命长、安装维护简单、智能融合便捷等优势。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    分布式光纤感温装置是我司根据当前行业现状，自主研发的基于定位原理的分布式测温产品。分布式光纤温度传感器，通常用在检测空间温度分布的系统，
                    具有监测距离长、全域分布无盲点、定位精确、无源防爆、抗干扰、对环境要求低、使用寿命长、安装维护简单、智能融合便捷等优势。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    可应用于各类场景：电力电缆实时监测：针对已投运的电缆，在电缆正常运行的情况下，可以实时监测电缆的发热情况和局部过热的情况。架空线路电缆的检测：针对架空线路传输容量的增高，对关键地点导线进行监测。
                    电缆竖井，夹层，隧道等监测预警：当电缆竖井夹层内电缆温度过高，产生报警时，可提前预警，开启应急通道，减少损失。
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url5: 'http://home.hzhjny.com/var/file/product43.png',
                srcList5: [
                    'http://home.hzhjny.com/var/file/product43.png',
                ],



                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>