<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url15" :preview-src-list="srcList15">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">杆塔倾斜在线监测装置</div>
                    <div class="detaildesr">
                        杆塔倾斜在线监测装置是一套基于高精度定位与多传感器融合的输电线路杆塔倾斜在线监测终端，能全天候监测杆塔倾斜情况、晃动幅值与晃动轨迹数据，并能通过4G上报数据。
                        使维护人员能够在后端查看现场数据，及时发现隐患、排除隐患，提高输电线路工作的可靠性。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    杆塔倾斜在线监测装置是一套基于高精度定位与多传感器融合的输电线路杆塔倾斜在线监测终端，能全天候监测杆塔倾斜情况、晃动幅值与晃动轨迹数据，并能通过4G上报数据。
                    使维护人员能够在后端查看现场数据，及时发现隐患、排除隐患，提高输电线路工作的可靠性。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    杆塔倾斜在线监测装置位于输电物联网的感知层，是基于高精度定位与多传感器融合的在线监测系统，结合最新的北斗高精度定位技术与工业级传感器技术
                    ，可对在恶劣环境中运行的高压输电线路的杆塔倾斜角度和幅度进行实时采集，并通过无线网络将监测信息发送至物联管理平台、全景监控平台。
                </div>
            </el-tab-pane>
            <el-tab-pane label="参数" name="third">
                <el-descriptions class="margin-top" title="" :column="2" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            测试范围
                        </template>
                        -10°~10°
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            分辨率
                        </template>
                        0.001°
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            准确度
                        </template>
                        小于0.05°
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            输出方式
                        </template>
                        LORA，433M
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            零位重复性
                        </template>
                        小于0.01°
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            电池容量
                        </template>
                        60Ah/12V
                    </el-descriptions-item>
                </el-descriptions>

                <el-descriptions class="margin-top" title="" :column="1" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            采集周期
                        </template>
                        默认2分钟（可根据需求定制）
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            采集处理
                        </template>
                        自动采集和受控采集，具备智能数据处理与判别，数据循环存储90天
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            供电时长
                        </template>
                        持续无光照可以维持装置正常运行15天的供电要求
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            供电方式
                        </template>
                        可采用市电、太阳能供电、风光互补供电、地线感应供电
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            通讯性能
                        </template>
                        配置4G APN通信模块，关键数据采用算法加密后再传输。通信方式满足电力专网自适应要求
                    </el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                size: 'medium',
                textarea2: '',
                url15: 'http://home.hzhjny.com/var/file/product25.png',
                srcList15: [
                    'http://home.hzhjny.com/var/file/product25.png',
                ],


                activeNames: 'first',
            }
        },
        methods: {
            newsdetail() {
                window.location.href = '/newsdetail'
            },

            newsdetail1() {
                window.location.href = '/newsdetail1'
            },

            newsdetail2() {
                window.location.href = '/newsdetail2'
            }
        },
    }
</script>