<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url9" :preview-src-list="srcList9">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">无线双工多方通话系统</div>
                    <div class="detaildesr">
                        无线双工多方通话系统是一种完全替代对讲机在特殊作业现场进行语音交流的通讯设备。满足高空作业、带电作业、消防作业等使用环境需求，实现无障碍沟通。
                        并且在相互沟通过程中实现双向语音实时对讲，同时解放双手，让现场作业更为安全、高效。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    无线双工多方通话系统是一种完全替代对讲机在特殊作业现场进行语音交流的通讯设备。满足高空作业、带电作业、消防作业等使用环境需求，实现无障碍沟通。
                    并且在相互沟通过程中实现双向语音实时对讲，同时解放双手，让现场作业更为安全、高效。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr" style="text-indent: 0px;">
                    ·降噪功能的无线麦克风，适合于嘈杂开放的环境；<br>
                    ·免注册，开机即用且具备信号自动恢复连接功能；<br>
                    ·通过主机麦克风指挥，使您更加有序的进行工作；<br>
                    ·2.4GHZ频段数字技术，使您的通讯没有串扰，更加可靠；<br>
                    ·现场负责人通过主机指挥，使团队作业更加高效；<br>
                    ·2小时快速充电，带车载充电功能，工作更加高效；<br>
                    ·标配五方实时通话。(单主机技术升级后最多可扩展为16方实时通话)。
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url9: 'http://home.hzhjny.com/var/file/product34.png',
                srcList9: [
                    'http://home.hzhjny.com/var/file/product34.png',
                ],


                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>