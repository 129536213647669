<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url9" :preview-src-list="srcList9">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">便携式电动升降机</div>
                    <div class="detaildesr">
                        便携式自动升降机（简称“小飞侠”）是利用电池驱动的自动爬升装置，他的功能就像一个简易电梯。装置开启后，电机旋转带动绞盘把人员轻松送至百米导线或降至地面，
                        既能省去了繁重危险的爬塔步骤，节省人员体力、降低安全风险，还能减少检修时间、提高工作效率。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    便携式自动升降机（简称“小飞侠”）是利用电池驱动的自动爬升装置，他的功能就像一个简易电梯。装置开启后，电机旋转带动绞盘把人员轻松送至百米导线或降至地面，
                    既能省去了繁重危险的爬塔步骤，节省人员体力、降低安全风险，还能减少检修时间、提高工作效率。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    设备可快速、安全、可靠地将施工人员送达所需要的高度。便携式自动升降机通过电池驱动，在经过认证的标准绳索上，上升或下降，使得在检测、检修、维修时行动安全、快捷、灵活，同时帮您节省时间并降低成本。
                    在难以到达的作业面或难以安装其他设备的作业环境中，其优势尤为突出。设备易于安装和拆卸，与其他提升设备及脚手架相比更有其独特的优势。
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url9: 'http://home.hzhjny.com/var/file/product31.png',
                srcList9: [
                    'http://home.hzhjny.com/var/file/product31.png',
                ],


                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>