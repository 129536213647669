<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url7" :preview-src-list="srcList7">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">声纹成像监测系统</div>
                    <div class="detaildesr">
                        高压电缆铅封涡流检测系统是针对电缆线路接头工艺的检测设备。
                        封铅涡流检测系统由高空输变电缆铅封检测模块、地面监控用输变电缆铅封检测模块、专用信号传输电缆、专用检测探头、工业笔记本电脑等组成。能透过15mm-20mm的包覆层，在大于35米以上的高空，
                        对运行状态下的高压电缆铅封层缺陷进行有效的检测。检测通道获取的信号可以阻抗平面图和时基扫描图实时显示于屏幕。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    高压电缆铅封涡流检测系统是针对电缆线路接头工艺的检测设备。
                    封铅涡流检测系统由高空输变电缆铅封检测模块、地面监控用输变电缆铅封检测模块、专用信号传输电缆、专用检测探头、工业笔记本电脑等组成。能透过15mm-20mm的包覆层，在大于35米以上的高空，
                    对运行状态下的高压电缆铅封层缺陷进行有效的检测。检测通道获取的信号可以阻抗平面图和时基扫描图实时显示于屏幕。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    设备具有能够适应各种不同金属材、线材等的检测要求，并且由于采用全数字化设计，因此能够在仪器内建立多个标准检测程序或专家系统，
                    方便用户在改换产品规格时调用，备有输入、输出控制接口，方便检测现场与各种设备相连接，并同步工作，构成一个高度自动化的整体。
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url7: 'http://home.hzhjny.com/var/file/product27.png',
                srcList7: [
                    'http://home.hzhjny.com/var/file/product27.png',
                ],


                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>