<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url2" :preview-src-list="srcList2">
                    </el-image>
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">微拍监测装置-云台式</div>
                    <div class="detaildesr">
                        云台式微拍视频装置是集摄像、存储、传输、太阳能取电为一体的工业化、智能化、一体化设计的输电线路图像视频监测装置。其主要为了针对输电线廊区域
                        、塔基区域等周边环境实现全面的掌握，可利用云台特性根据需要进行预置位定时抓拍监控并实时调取视频数据，第一时间掌握现场情况。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    云台式微拍视频装置是集摄像、存储、传输、太阳能取电为一体的工业化、智能化、一体化设计的输电线路图像视频监测装置。其主要为了针对输电线廊区域
                    、塔基区域等周边环境实现全面的掌握，可利用云台特性根据需要进行预置位定时抓拍监控并实时调取视频数据，第一时间掌握现场情况。
                </div>
            </el-tab-pane>

            <el-tab-pane label="参数" name="third">
                <el-descriptions class="margin-top" title="" :column="1" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            摄像性能
                        </template>
                        具备云台功能的可变焦摄像头以及定焦摄像头，分别为<el-tag size="small">200w</el-tag>像素和<el-tag
                            size="small">1600w</el-tag>像素
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            云台功能
                        </template>
                        旋转角度0度-360度，俯仰角度0-270度，精度0.1度
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            安装位置
                        </template>
                        安装于杆塔上。外壳与结构件由304不锈钢材质或铸铁材质构成，适用角钢塔、砼杆、钢管塔等不同杆塔
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            供电方式
                        </template>
                        光能+磷酸铁锂电池+超级电容电源供电； 具备远端电源管理功能；具备外置电源接口
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            抗干扰能力
                        </template>
                        满足输电线路环境下的强电场、强磁场的恶劣工作环境要求
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            数据通信
                        </template>
                        配置4G APN通信模块，关键数据应采用算法加密后再传输。满足电力专网自适应要求
                    </el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                size: 'medium',
                input1: '',
                input2: "",
                textarea2: '',
                url2: 'http://home.hzhjny.com/var/file/product12.png',
                srcList2: [
                    'http://home.hzhjny.com/var/file/product12.png',
                ],
                activeNames: 'first',
            }
        },
        methods: {
            newsdetail() {
                window.location.href = '/newsdetail'
            },

            newsdetail1() {
                window.location.href = '/newsdetail1'
            },

            newsdetail2() {
                window.location.href = '/newsdetail2'
            }
        },
    }
</script>