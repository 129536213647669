<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url5" :preview-src-list="srcList5">
                    </el-image>
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">架空线路智能驱鸟装置</div>
                    <div class="detaildesr">
                        架空线路智能驱鸟装置是一款专为架空线路研发设计的驱鸟产品，利用 CT 取电和蓄电池供电，特别适合野外杆塔及不方便提供电源的变电站使用。
                        装置采用物理驱鸟，通过声音+闪光的方式来恶化鸟类生存环境，将其驱赶于杆塔、变电站等之外筑巢，以达到杜绝鸟害，保证线路或变电站安全的目的
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    架空线路智能驱鸟装置是一款专为架空线路研发设计的驱鸟产品，利用 CT 取电和蓄电池供电，特别适合野外杆塔及不方便提供电源的变电站使用。
                    装置采用物理驱鸟，通过声音+闪光的方式来恶化鸟类生存环境，将其驱赶于杆塔、变电站等之外筑巢，以达到杜绝鸟害，保证线路或变电站安全的目的
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr" style="text-indent: 0px;">
                    ·可带电安装，不受高压影响；<br>
                    ·采用超声波以及多色激光爆闪灯等多种方式驱鸟，鸟儿无适应性；<br>
                    ·内置雷达探头，可实时对驱鸟器覆盖区域进行雷达探测，识别鸟类更加精准；<br>
                    ·采用CT 线圈取电方式，超级电容作为储能供电，无需后续维护成本，工作效率高。
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url5: 'http://home.hzhjny.com/var/file/product42.png',
                srcList5: [
                    'http://home.hzhjny.com/var/file/product42.png',
                ],



                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>