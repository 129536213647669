<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url4" :preview-src-list="srcList4">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product14.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">微气象监测装置</div>
                    <div class="detaildesr">
                        微气象在线监测系统是针对输电线路、变电站等在特殊地点的气象环境监测而设计的多要素微气象监测系统。可监测环境温度、湿度、风速、风向、气压、光辐射、
                        降雨量等气象参数，并将采集到的各种气象参数及其变化状况，通过GPRS无线网络实时的传送到系统主机中。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    微气象在线监测系统是针对输电线路、变电站等在特殊地点的气象环境监测而设计的多要素微气象监测系统。可监测环境温度、湿度、风速、风向、气压、光辐射、
                    降雨量等气象参数，并将采集到的各种气象参数及其变化状况，通过GPRS无线网络实时的传送到系统主机中。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    适合安装在大跨越、易覆冰区和强风区等特殊区域区段 (高海拔地区的迎风山坡、垭口、风道、水面附近、积雪或覆冰时间较长的地区)，也可安装在因气象因素导致故障
                    (如风偏、非同期摇摆、脱冰跳跃、舞动等)频发的线路区段，还可安装在传统气象监测盲区、行政区域交界、人烟稀少区、高山大岭区等无气象监测台站的区域。
                    系统主机可对采集到的数据进行存储、统计与分析，并将所有数据通过各种报表、统计图、曲线等方式显示给用户。当出现异常情况时，系统会以多种方式发出预报警信息，提示管理人员应对报警点予以重视或采取必要的预防措施。
                </div>
            </el-tab-pane>
            <el-tab-pane label="参数" name="third">
                <el-descriptions class="margin-top" title="" :column="1" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            摄像性能
                        </template>
                        可选配<el-tag size="small">1600</el-tag>万像素定焦摄像头+<el-tag size="small">200</el-tag>万像素夜视定焦摄像头
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            安装位置
                        </template>
                        安装于杆塔上。外壳与结构件由304不锈钢材质或铸铁材质构成，适用角钢塔、砼杆、钢管塔等不同杆塔
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            供电方式
                        </template>
                        光能+磷酸铁锂电池+超级电容电源供电； 具备远端电源管理功能；具备外置电源接口
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            工作方式
                        </template>
                        4G APN无线通信方式，与后台中心站或子站进行4G APN通信
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            抗干扰能力
                        </template>
                        满足输电线路环境下的强电场、强磁场的恶劣工作环境要求
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            数据采集
                        </template>
                        具备复杂地形环境温度、湿度、气压、辐射、风速、风向、雨量等参数的准确测量功能
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            数据通信
                        </template>
                        配置4G APN通信模块，关键数据采用算法加密后再传输。通信方式满足电力专网自适应要求
                    </el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                size: 'medium',
                input2: "",
                textarea2: '',
                url4: 'http://home.hzhjny.com/var/file/product14.png',
                srcList4: [
                    'http://home.hzhjny.com/var/file/product14.png',
                ],

                activeNames: 'first',
            }
        },
        methods: {
            newsdetail() {
                window.location.href = '/newsdetail'
            },

            newsdetail1() {
                window.location.href = '/newsdetail1'
            },

            newsdetail2() {
                window.location.href = '/newsdetail2'
            }
        },
    }
</script>