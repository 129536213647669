<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url5" :preview-src-list="srcList5">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">AI技术杆塔物联代理装置</div>
                    <div class="detaildesr">
                        输电线路物联代理装置是智慧线路中信息汇集和处理的核心单元，装置设计定位为一款开放式硬件，具备多种有线、无线接口，可接入不同厂家的传感器数据，
                        可结合不同的监测类型配置相应的边缘算法，实现输电线路杆塔本体、输电通道、线路周边环境实现全景监测。输电线路物联代理装置是众多智能感知终端统一有序物联接入的关键。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    输电线路物联代理装置是智慧线路中信息汇集和处理的核心单元，装置设计定位为一款开放式硬件，具备多种有线、无线接口，可接入不同厂家的传感器数据，
                    可结合不同的监测类型配置相应的边缘算法，实现输电线路杆塔本体、输电通道、线路周边环境实现全景监测。输电线路物联代理装置是众多智能感知终端统一有序物联接入的关键。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr" style="text-indent: 0px;">
                    ·物联代理装置具有边缘计算功能，实现了计算算力的分布下沉。节省了大量的网络带宽，减轻了中心系统的计算负荷。<br>
                    ·本系统提供与物联管理平台交互的能力，可通过本地和远程修改。具备容器管理功能。接收到物联管理平台发送的配置信息命令，并将相关命令的执行结果反馈给物联平台。具备应用管理功能，支持“启动应用”、“停止应用”等远程操作。<br>
                    ·可选配 AI 加速模块，实现本地实时图片、视频的各种状态，故障识别。
                </div>
            </el-tab-pane>
            <el-tab-pane label="参数" name="third">
                <el-descriptions class="margin-top" title="" :column="1" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            软硬件性能
                        </template>
                        物联代理装置采用国产CPU，操作系统基于 linux4.9.28 内核移植，基于“硬件平台化，业务APP化”的设计理念，对各个端口的数据流进行整合
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            安装位置
                        </template>
                        安装于杆塔上。外壳与结构件由304不锈钢材质或铸铁材质构成，适用角钢塔、砼杆、钢管塔等不同杆塔
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            供电方式
                        </template>
                        光能+磷酸铁锂电池+超级电容电源供电； 具备远端电源管理功能；具备外置电源接口
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            工作方式
                        </template>
                        对物理或无线接入设备的监测信息进行整合管理
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            抗干扰能力
                        </template>
                        满足输电线路环境下的强电场、强磁场的恶劣工作环境要求
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            数据通信
                        </template>
                        配置4G APN通信模块，关键数据采用算法加密后再传输。通信方式满足电力专网自适应要求
                    </el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url5: 'http://home.hzhjny.com/var/file/product38.png',
                srcList5: [
                    'http://home.hzhjny.com/var/file/product38.png',
                ],



                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>