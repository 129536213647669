<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url6" :preview-src-list="srcList6">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">耐张线夹X光无损检测</div>
                    <div class="detaildesr">
                        输电线路耐张线夹和接续管X光探伤检测系统，该系统以X射线数字成像技术为基础，利用非破坏整理施工工艺性质。对耐张线夹进行无损成像检测。针对线路金具的技术特性和运行环境，
                        实现了对线路耐张线夹和接续管的高精度成像。系统包括便携式变频气绝缘X射线探伤机、远程便携式探伤机控制器、辐射剂量报警仪、GSY系列高压声光型验电器、专用工器具等组成。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    输电线路耐张线夹和接续管X光探伤检测系统，该系统以X射线数字成像技术为基础，利用非破坏整理施工工艺性质。对耐张线夹进行无损成像检测。针对线路金具的技术特性和运行环境，
                    实现了对线路耐张线夹和接续管的高精度成像。系统包括便携式变频气绝缘X射线探伤机、远程便携式探伤机控制器、辐射剂量报警仪、GSY系列高压声光型验电器、专用工器具等组成。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    耐张线夹和接续管X光探伤检测系统在高空检测中，能够第一时间检测出设备压接缺陷。针对新建线路，耐张线夹通过X光探伤检测能够及时发现耐张线夹内部缺陷；
                    发现故障隐患，能够现场出示具体缺陷内容。能在实际运用中帮助运维检修人员及时处理故障，这对输电线路安全运行具有重要指导意义。
                </div>
            </el-tab-pane>

        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url6: 'http://home.hzhjny.com/var/file/product26.png',
                srcList6: [
                    'http://home.hzhjny.com/var/file/product26.png',
                ],

                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>