import { render, staticRenderFns } from "./pdproduct5.vue?vue&type=template&id=512c6068&scoped=true"
import script from "./pdproduct5.vue?vue&type=script&lang=js"
export * from "./pdproduct5.vue?vue&type=script&lang=js"
import style0 from "./pdproduct5.vue?vue&type=style&index=0&id=512c6068&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "512c6068",
  null
  
)

export default component.exports