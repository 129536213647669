<template>
  <div class="headermain">
    <div class="headerleft">
      <img src="http://home.hzhjny.com/var/file/logo1.png" alt class="logoimg" />
    </div>
    <div class="headerright">
      <router-link to="/">
        <div class="headline">
          首页
        </div>
      </router-link>
      <router-link to="/about">
        <div class="headline">
          关于我们
        </div>
      </router-link>
      <router-link to="/productdetail/sdproduct1">
        <div class="headline">
          产品中心
        </div>
      </router-link>
      <router-link to="/news">
        <div class="headline">
          新闻资讯
        </div>
      </router-link>
      <router-link to="/personrecruit">
        <div class="headline">
          人才招聘
        </div>
      </router-link>

      <router-link to="/callus">
        <div class="headline">
          联系我们
        </div>
      </router-link>

    </div>
  </div>
</template>

<style type="text/scss" scoped>
  .logoimg {
  /* width: 100px; */
  height: 80px;
  margin-right: 15px;
}



.headline{
  padding: 0 20px;
}

.headline:hover{
  background: #026bb4;
  color: white;
  cursor: pointer;
}

.router-link-exact-active{
  background: #026bb4;
  cursor: pointer;
}

.router-link-exact-active .headline{
  color: white;
}


.headermain {
  height:80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* background: linear-gradient(to right, #fffeff 0%, #d7fffe 100%); */
  background: white;
  padding: 0 20px;
  box-shadow: 0px 0px 8px 0px rgba(110, 114, 117, 0.5) !important;
}

.headerleft {
  font-size: 24px;
  /* font-family: Alibaba PuHuiTi; */
  font-weight: bold;
  color: #333333;
  line-height: 9px;
  display: flex;
  align-items: center;
}

.headerright {
  text-align: right;
  display: flex;
  align-items: center;
  height: 70px;
  line-height: 70px;
}

.router-link-exact-active{
  color: #2c3e50;
  text-decoration: none;
}

a{
  text-decoration: none;
  color: #2c3e50;
}

</style>

<script>
  export default {
    data() {
      return {
        activeIndex: '/'
      };
    },
    methods: {


    }
  };
</script>