<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url9" :preview-src-list="srcList9">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">智能AI识别布控球</div>
                    <div class="detaildesr">
                        智能AI识别布控球满足客户多场景移动监控需求，产品搭载高清摄像机，高精度云台高性能锂电池，远程云控制等，具备远程高清监控、无线视频图传、语音对讲、AI识别、本地存储等功能，
                        能够对作业现场进行实时录像监控远程回传，相关人员也可以登录平台进行远程喊话指挥，有效提升现场安全管理水平。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    智能AI识别布控球满足客户多场景移动监控需求，产品搭载高清摄像机，高精度云台高性能锂电池，远程云控制等，具备远程高清监控、无线视频图传、语音对讲、AI识别、本地存储等功能，
                    能够对作业现场进行实时录像监控远程回传，相关人员也可以登录平台进行远程喊话指挥，有效提升现场安全管理水平。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr" style="text-indent: 0px;">
                    ·对目标区域进行全局和细节监测；<br>
                    ·支持3D控球一键聚焦；<br>
                    ·双目协同实现人员及车辆跟踪监控；<br>
                    ·支持人脸、安全帽、口罩、工装、工程车辆识别和抓拍；<br>
                    ·支持北斗、GPS、混合定位，能准确定位作业现场的具体位置；<br>
                    ·带有伸缩式三脚架，可直接吸附在车辆上，随时可将设备拆下。
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url9: 'http://home.hzhjny.com/var/file/product35.png',
                srcList9: [
                    'http://home.hzhjny.com/var/file/product35.png',
                ],


                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>