<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url9" :preview-src-list="srcList9">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">高压电缆接地阻抗带电检测装置</div>
                    <div class="detaildesr">
                        电缆线路巡视检查是监视和掌握电缆线路和所有附属设备的运行情况，及时发现和消除电缆 线路和所有附属设备异常和缺陷，预防事故发生，确保电缆线路安全运行的主要工作。
                        针对高压电缆接地回路系统面临的问题，我司研发了高压电缆接地阻抗带电检测装置HJ-C/HLZZ100，重点解决系统封铅不良等引起接地电阻变大的情况，
                        设备具有三相同测，时效性高，灵敏度高，准确度高等特点，可以判断连接不良及施工工艺等问题带来的毫欧级微小电阻变化。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    电缆线路巡视检查是监视和掌握电缆线路和所有附属设备的运行情况，及时发现和消除电缆 线路和所有附属设备异常和缺陷，预防事故发生，确保电缆线路安全运行的主要工作。
                    针对高压电缆接地回路系统面临的问题，我司研发了高压电缆接地阻抗带电检测装置HJ-C/HLZZ100，重点解决系统封铅不良等引起接地电阻变大的情况，
                    设备具有三相同测，时效性高，灵敏度高，准确度高等特点，可以判断连接不良及施工工艺等问题带来的毫欧级微小电阻变化。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr" style="text-indent: 0px;">
                    ·智能化程度高，软件系统自动计算各参数量；结果自动生成；<br>
                    ·缩短巡检时长，三相同时测量操作简单便捷，即时获取接地回路电阻状态，把握变化趋势；<br>
                    ·灵敏度高，激发脉冲信号，可发现系统mΩ级电阻缺陷，提前处理，避免发生击穿事故；
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url9: 'http://home.hzhjny.com/var/file/product29.png',
                srcList9: [
                    'http://home.hzhjny.com/var/file/product29.png',
                ],


                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>