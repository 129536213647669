<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url9" :preview-src-list="srcList9">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">剩余电流监测装置</div>
                    <div class="detaildesr">
                        剩余电流监测装置（低压侧）是一款集剩余电流、温度、全电量测量于一体的高精度智能装置。应用于电气火灾监控系统、
                        智慧式用电安全监控系统的前端，完成剩余电流、温度、电流的实时监控、预警、报警和保护，及时提醒运维人员排查故障以消除电气隐患。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    剩余电流监测装置（低压侧）是一款集剩余电流、温度、全电量测量于一体的高精度智能装置。应用于电气火灾监控系统、
                    智慧式用电安全监控系统的前端，完成剩余电流、温度、电流的实时监控、预警、报警和保护，及时提醒运维人员排查故障以消除电气隐患。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr" style="text-indent: 0px;">
                    ·设备一般应用于开关柜中，支持高精度的剩余电流、温度和全电量测量功能；<br>
                    ·支持剩余电流、温度、电流过流、欠压、过压、缺相和不平衡等保护功能；<br>
                    ·支持掉电检测功能，掉电后支持上传掉电信息，并可持续工作 10s 以上。
                </div>
            </el-tab-pane>
            <el-tab-pane label="参数" name="third">
                <el-descriptions class="margin-top" title="" :column="1" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            基本测量
                        </template>
                        三/单相电流及平均值、剩余电流、温度
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            电能计量
                        </template>
                        正向有功电能、反向有功电能、有功电能总和、有功电能净值
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            电能质量
                        </template>
                        功率因数及总值、有功功率及总值、三相电压/电流角度
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            需量功能
                        </template>
                        三相电流/总有功功率/总无功功率/总视在功率的实时需量
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            事件记录
                        </template>
                        100 个事件记录，分辨率 1ms；包括电流报警、温度报警等
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            数据通信
                        </template>
                        1 个 RS-485 口、NB-IOT或 4G通信三选一
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            接线诊断
                        </template>
                        电压/电流缺相诊断、电压/电流相序诊断、三相及总有功功率方向诊断
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            定值越限
                        </template>
                        可设10组定值越限，监视电压、电流、等变量，可产生 SOE、触发继电器动作
                    </el-descriptions-item>
                </el-descriptions>

            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url9: 'http://home.hzhjny.com/var/file/product22.png',
                srcList9: [
                    'http://home.hzhjny.com/var/file/product22.png',
                ],


                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>