<template>
    <div class="backmain">
        <div>
            <img src="http://home.hzhjny.com/var/file/productback.jpg" alt="" class="bannerimg">
        </div>
        <div class="content">
            <el-container>
                <el-aside width="280px">
                    <el-menu :default-active="activetab" @select="tabClick">
                        <el-submenu index="1">
                            <template slot="title"><i class="el-icon-message"></i>输电产品</template>

                            <el-menu-item index="/productdetail/sdproduct1">
                                <router-link to="/productdetail/sdproduct1">
                                    <span class="tabmenu" style="font-weight: bold;">
                                        导线精灵
                                    </span>
                                </router-link>
                            </el-menu-item>

                            <el-menu-item index="/productdetail/sdproduct2">
                                <router-link to="/productdetail/sdproduct2">
                                    <span class="tabmenu" style="font-weight: bold;">
                                        微拍监测装置-枪机式
                                    </span>
                                </router-link>
                            </el-menu-item>
                            <el-menu-item index="/productdetail/sdproduct3">
                                <router-link to="/productdetail/sdproduct3">
                                    <span class="tabmenu" style="font-weight: bold;">
                                        微拍监测装置-云台式
                                    </span>
                                </router-link>
                            </el-menu-item>
                            <el-menu-item index="/productdetail/sdproduct4">
                                <router-link to="/productdetail/sdproduct4">
                                    <span class="tabmenu" style="font-weight: bold;">
                                        三跨在线视频装置
                                    </span>
                                </router-link>
                            </el-menu-item>
                            <el-menu-item index="/productdetail/sdproduct5">
                                <router-link to="/productdetail/sdproduct5">
                                    <span class="tabmenu" style="font-weight: bold;">
                                        微气象监测装置
                                    </span>
                                </router-link>
                            </el-menu-item>
                            <el-menu-item index="/productdetail/sdproduct6">
                                <router-link to="/productdetail/sdproduct6">
                                    <span class="tabmenu">
                                        杆塔倾斜在线监测装置
                                    </span>
                                </router-link>
                            </el-menu-item>
                            <el-menu-item index="/productdetail/sdproduct7">
                                <router-link to="/productdetail/sdproduct7">
                                    <span class="tabmenu">
                                        覆冰拉力在线监测装置
                                    </span>
                                </router-link>
                            </el-menu-item>

                            <el-menu-item index="/productdetail/sdproduct8">
                                <router-link to="/productdetail/sdproduct8">
                                    <span class="tabmenu">
                                        模拟导线覆冰监测装置
                                    </span>
                                </router-link>
                            </el-menu-item>

                            <el-menu-item index="/productdetail/sdproduct9">
                                <router-link to="/productdetail/sdproduct9">
                                    <span class="tabmenu">
                                        架空线路分布式故障定位监测装置
                                    </span>
                                </router-link>
                            </el-menu-item>

                            <el-menu-item index="/productdetail/sdproduct10">
                                <router-link to="/productdetail/sdproduct10">
                                    <span class="tabmenu">
                                        AI技术杆塔物联代理装置
                                    </span>
                                </router-link>
                            </el-menu-item>

                            <el-menu-item index="/productdetail/sdproduct11">
                                <router-link to="/productdetail/sdproduct11">
                                    <span class="tabmenu">
                                        绝缘子污秽在线监测装置
                                    </span>
                                </router-link>
                            </el-menu-item>

                            <el-menu-item index="/productdetail/sdproduct12">
                                <router-link to="/productdetail/sdproduct12">
                                    <span class="tabmenu">
                                        防山火红外监测装置
                                    </span>
                                </router-link>
                            </el-menu-item>

                            <el-menu-item index="/productdetail/sdproduct13">
                                <router-link to="/productdetail/sdproduct13">
                                    <span class="tabmenu">
                                        智能激光语音综合驱鸟装置
                                    </span>
                                </router-link>
                            </el-menu-item>

                            <el-menu-item index="/productdetail/sdproduct14">
                                <router-link to="/productdetail/sdproduct14">
                                    <span class="tabmenu">
                                        架空线路智能驱鸟装置
                                    </span>
                                </router-link>
                            </el-menu-item>

                            <el-menu-item index="/productdetail/sdproduct17">
                                <router-link to="/productdetail/sdproduct17">
                                    <span class="tabmenu">
                                        智能综合接地箱
                                    </span>
                                </router-link>
                            </el-menu-item>

                            <el-menu-item index="/productdetail/sdproduct15">
                                <router-link to="/productdetail/sdproduct15">
                                    <span class="tabmenu">
                                        分布式光纤感温装置
                                    </span>
                                </router-link>
                            </el-menu-item>

                            <el-menu-item index="/productdetail/sdproduct16">
                                <router-link to="/productdetail/sdproduct16">
                                    <span class="tabmenu">
                                        分布式光纤振动防外破系统
                                    </span>
                                </router-link>
                            </el-menu-item>

                        </el-submenu>
                        <el-submenu index="2">
                            <template slot="title"><i class="el-icon-chat-square"></i>配电产品</template>
                            <el-menu-item index="/productdetail/pdproduct1">
                                <router-link to="/productdetail/pdproduct1">
                                    <span class="tabmenu" style="font-weight: bold;">
                                        行波故障定位及预警装置
                                    </span>
                                </router-link>
                            </el-menu-item>
                            <el-menu-item index="/productdetail/pdproduct2">
                                <router-link to="/productdetail/pdproduct2">
                                    <span class="tabmenu">
                                        智慧地钉
                                    </span>
                                </router-link>
                            </el-menu-item>
                            <el-menu-item index="/productdetail/pdproduct3">
                                <router-link to="/productdetail/pdproduct3">
                                    <span class="tabmenu">
                                        电力智能井盖
                                    </span>
                                </router-link>
                            </el-menu-item>
                            <el-menu-item index="/productdetail/pdproduct4">
                                <router-link to="/productdetail/pdproduct4">
                                    <span class="tabmenu">
                                        无线温度传感器
                                    </span>
                                </router-link>
                            </el-menu-item>
                            <el-menu-item index="/productdetail/pdproduct5">
                                <router-link to="/productdetail/pdproduct5">
                                    <span class="tabmenu">
                                        电缆接头温度及局放测量装置
                                    </span>
                                </router-link>
                            </el-menu-item>

                        </el-submenu>
                        <el-submenu index="3">
                            <template slot="title"><i class="el-icon-lightning"></i>变电产品</template>
                            <el-menu-item index="/productdetail/bdproduct1">
                                <router-link to="/productdetail/bdproduct1">
                                    <span class="tabmenu">
                                        双光谱云台测温热像仪
                                    </span>
                                </router-link>
                            </el-menu-item>
                            <el-menu-item index="/productdetail/bdproduct2">
                                <router-link to="/productdetail/bdproduct2">
                                    <span class="tabmenu">
                                        声纹成像监测系统
                                    </span>
                                </router-link>
                            </el-menu-item>
                            <el-menu-item index="/productdetail/bdproduct3">
                                <router-link to="/productdetail/bdproduct3">
                                    <span class="tabmenu">
                                        无线太阳能红外监测系统
                                    </span>
                                </router-link>
                            </el-menu-item>
                            <el-menu-item index="/productdetail/bdproduct4">
                                <router-link to="/productdetail/bdproduct4">
                                    <span class="tabmenu">
                                        剩余电流监测装置
                                    </span>
                                </router-link>
                            </el-menu-item>
                        </el-submenu>
                        <el-submenu index="4">
                            <template slot="title"><i class="el-icon-trophy"></i>检测服务类</template>
                            <el-menu-item index="4-1">
                                <router-link to="/productdetail/tzproduct1">
                                    <span class="tabmenu">
                                        耐张线夹X光无损检测
                                    </span>
                                </router-link>
                            </el-menu-item>
                            <el-menu-item index="4-2">
                                <router-link to="/productdetail/tzproduct2">
                                    <span class="tabmenu">
                                        高压电缆铅封涡流检测
                                    </span>
                                </router-link>
                            </el-menu-item>
                        </el-submenu>

                        <el-submenu index="5">
                            <template slot="title"><i class="el-icon-setting"></i>电力安全辅助设备</template>
                            <el-menu-item index="4-3">
                                <router-link to="/productdetail/tzproduct3">
                                    <span class="tabmenu">
                                        车载电力防外破报警系统
                                    </span>
                                </router-link>
                            </el-menu-item>
                            <el-menu-item index="4-4">
                                <router-link to="/productdetail/tzproduct4">
                                    <span class="tabmenu">
                                        高压电缆接地阻抗带电检测装置
                                    </span>
                                </router-link>
                            </el-menu-item>

                            <el-menu-item index="4-5">
                                <router-link to="/productdetail/tzproduct5">
                                    <span class="tabmenu">
                                        激光除异物装置
                                    </span>
                                </router-link>
                            </el-menu-item>

                            <el-menu-item index="4-6">
                                <router-link to="/productdetail/tzproduct6">
                                    <span class="tabmenu">
                                        便携式电动升降机
                                    </span>
                                </router-link>
                            </el-menu-item>

                            <el-menu-item index="4-7">
                                <router-link to="/productdetail/tzproduct7">
                                    <span class="tabmenu">
                                        防坠落导轨
                                    </span>
                                </router-link>
                            </el-menu-item>

                            <el-menu-item index="4-8">
                                <router-link to="/productdetail/tzproduct8">
                                    <span class="tabmenu">
                                        便携式红外测温成像仪
                                    </span>
                                </router-link>
                            </el-menu-item>

                            <el-menu-item index="4-9">
                                <router-link to="/productdetail/tzproduct9">
                                    <span class="tabmenu">
                                        无线双工多方通话系统
                                    </span>
                                </router-link>
                            </el-menu-item>

                            <el-menu-item index="4-10">
                                <router-link to="/productdetail/tzproduct10">
                                    <span class="tabmenu">
                                        智能AI识别布控球
                                    </span>
                                </router-link>
                            </el-menu-item>

                        </el-submenu>
                    </el-menu>
                </el-aside>
                <el-container>
                    <router-view></router-view>
                </el-container>
            </el-container>
        </div>
    </div>
</template>

<style scoped>
    .bannerimg {
        width: 100%;
    }

    .backmain {
        width: 100%;
        background: white;
    }

    .el-menu-item.is-active {
        background-color: #ecf5ff;

    }




    .el-menu-item.is-active a {
        color: #409EFF;
        display: block;
        width: 100%;
    }

    .el-submenu .el-menu-item {
        padding: 0;
    }

    .content {
        width: 90%;
        margin: 0 auto;
        margin-top: 20px;
        padding-bottom: 30px;
        background: white;
    }

    .tabmenu {
        display: inline-block;
        width: 100%;
        height: 100%;
    }

    ::v-deep .el-submenu__title {
        font-size: 16px;
    }
</style>

<script>
    export default {
        data() {
            return {
                activetab: localStorage.getItem('productnum') || '/productdetail/sdproduct1'
            }
        },
        methods: {
            tabClick(e) {
                localStorage.setItem('productnum', e)
            },
        }
    }
</script>