<template>
    <div class="backmain">
        <div>
            <img src="http://home.hzhjny.com/var/file/newsback.jpg" alt="" class="bannerimg">
        </div>
        <div class="content">
            <div class="newstitle">
                中国电力：创新引领未来能源
            </div>
            <div style="text-align: center;color: #999;font-size: 14px;">
                2023年11月01日 18:49 来源：中国新闻网
            </div>
            <hr>
            <div class="imgmain">
                <img src="http://home.hzhjny.com/var/file/newsimg1.jpg" alt="" class="imgsize">
            </div>
            <div class="newscont">
                10月29日，中国电力面向全球成功发布6项科技创新成果，引领绿能新兴产业发展新潮流，进一步展现了其“打造科技创新引领型的世界一流绿色低碳能源供应商”的最新进展。
            </div>
            <div class="newscont">
                中国电力在2021年发布了新战略，制定了在2025年清洁能源装机占比超过90%的目标。“为实现这一目标，公司从几个方面着手。首先是全面优化调整煤电存量资产，通过实施‘两个联营’，大力提升了煤电作为能源保供‘压舱石’的作用，促进煤电向基础保障性和系统调节性电源的转变，大力推动煤电高质量发展，”高平在采访中介绍到：“更重要的是，我们大力实施清洁低碳能源和绿能新兴产业‘双轮驱动’，推进光伏、风电、水电、地热能、生物质能等清洁低碳能源可持续快速发展，尤其是在创新驱动发展方面，我们闯出了一条创新之路。”据了解，中国电力清洁能源装机占比预计到今年年底将达到超过75%。
            </div>
            <div class="newscont">
                在储能领域，该公司也取得了显著进展。2021年7月，中国电力打造专业化储能平台公司新源智储，经过两年的发展，这家公司出货量名列全国前三，获评国资委“科改示范标杆企业”、“国家高新技术企业”，已成为央企储能行业的头部梯队。
            </div>
            <div class="newscont">
                “新源智储不仅提供储能集成应用平台，还兼顾了电化学储能的上下游，产品序列也从大储能行业面向例如工商业储能、户用储能行业拓展，整条产品线非常齐全。”王冬容表示。
            </div>
            <div class="newscont">
                此外，中国电力加速深耕储能本质安全技术创新和产业落地，为储能电池行业的持续发展提供了新的可能性。当前中国电力布局了以电化学储能为主的一条路线，同时紧盯其他技术路线，包括压缩空气储能和飞轮储能。从国际市场角度来看，中国电力在风电、光伏等新能源领域以及绿电交通、储能技术方面都是处于国际领先的。当前，正在推动与墨西哥、哈萨克斯坦等多个国家建立储能合作。未来，该公司将继续在智利、巴西、澳大利亚等地区布局，逐步推动储能技术出海。
            </div>
            <div class="newscont">
                从海外市场回望广袤乡野，中国电力还积极融入乡村振兴和农业农村现代化大局，以可再生能源为主导，推动农村能源革命，支持乡村振兴战略。2021年，该公司成立了“双碳”助力乡村振兴的创新平台公司中电农创，以“产业”和“能源”双轮驱动，打通全产业链、供应链体系，集聚区域内可再生资源优势，赋能产业发展。同时大力拓展县域，将能源清洁绿色转型和农村开发结合，应用“光储直柔”技术，打造“零碳村”和“智慧村”示范，以“用户侧综合智慧能源”为主线，构建乡村振兴生态圈。
            </div>

            <div class="newscont">
                “我们大力推进光储直柔、光储充、交能融合等构成虚拟电厂的重要元素，全力做好底层搭建，打好虚拟电厂基础，在时机合适时迅速聚合，构建完善的虚拟电厂。”王冬容表示。据了解，中国电力在安徽省芜湖市打造的城市级虚拟电厂，做到了芜湖市60%以上的工业负荷全部接入了虚拟电厂体系，走在国内前沿。
            </div>
            <div class="newscont">
                今年7月，中国电力与国家电投签订协议，收购了五家清洁能源项目公司。“从3月份启动，到9月底全部完成，6个月的时间内完成如此大规模的交易，对中国电力的发展影响巨大。”寿如锋表示，两年前，中国电力发布了新战略，当时制定了清洁能源装机占比在今年年底超过70%的任务，目前已超额完成目标。
            </div>
            <div class="newscont">
                对于未来发展，中国电力将坚定战略转型目标，以打造“科技引领性世界一流绿色能源供应商”为目标，绿色低碳转型和科技创新并重，倍速发力绿能新兴产业新赛道。
            </div>
            <hr>
            <div class="bottomline">
                <div class="posttexttype" @click="lastperson" style="cursor: pointer;">
                    上一个：迎峰度冬电力供应有保障
                </div>
                <div class="posttexttype" @click="nextperson" style="cursor: pointer;">
                    下一个：国网浙江电力：技术标准推动新型电力系统省级示范区建设
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .bannerimg {
        width: 100%;
    }

    .backmain {
        width: 100%;
        background: white;
    }

    .content {
        width: 70%;
        margin: 0 auto;
        margin-top: 20px;
        padding-bottom: 30px;
        background: white;
    }

    .imgmain {
        text-align: center;
    }

    .imgsize {
        width: 80%;
        margin: 0 auto;
    }

    .newstitle {
        font-size: 36px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .newscont {
        font-size: 16px;
        line-height: 30px;
        color: #666;
        text-indent: 28px;
    }

    .fonttitle {
        font-weight: bold;
        text-indent: 28px;
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .posttexttype {
        font-size: 14px;
        color: #999;
    }

    .bottomline {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: ''
            }
        },
        methods: {
            lastperson() {
                window.location.href = '/newsdetail1'
            },
            nextperson() {
                window.location.href = '/newsdetail'
            }
        },
    }
</script>