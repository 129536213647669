<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url9" :preview-src-list="srcList9">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">防坠落导轨</div>
                    <div class="detaildesr">
                        防坠落导轨是在高空作业时，为防止上下移动过程中发生坠落而开发的装置，自投入应用以来，经过客户的使用证明，是一套安全可靠的产品。在输电铁塔、通信铁塔、照明铁塔等各种构造物的上下用路板、爬梯、扶手等位置，用专用固定金具
                        (沟槽型) 铝合金导轨。在进行维护检查等作业时，把安全器安装在导轨上，
                        作业过程中，万一作业人员手脱滑脱，安全器的制动板就会和寻轨紧密咬合而停止，防止坠落的发生。是一种保障工作人员生命安全的永久性设施。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    防坠落导轨是在高空作业时，为防止上下移动过程中发生坠落而开发的装置，自投入应用以来，经过客户的使用证明，是一套安全可靠的产品。在输电铁塔、通信铁塔、照明铁塔等各种构造物的上下用路板、爬梯、扶手等位置，用专用固定金具
                    (沟槽型) 铝合金导轨。在进行维护检查等作业时，把安全器安装在导轨上，作业过程中，万一作业人员手脱滑脱，安全器的制动板就会和寻轨紧密咬合而停止，防止坠落的发生。是一种保障工作人员生命安全的永久性设施。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    本防坠落导轨采用铝合金材质，表面经过防腐工艺处理，使用寿命在30年以上，一般不需要维护；防坠落导轨通过安全器卡住导轨实现锁止功能，采用杠杆比率和单位材料硬度差技术原理，安全器的杠杆板(45号钢)与铝合金导轨在硬度上有差距，
                    有效实现了安全器的杠杆板和铝合金导轨的紧密咬合并锁止；锁止距离短，2CM锁止，5CM 100%锁止，有效的防止二次灾害事故的发生。
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url9: 'http://home.hzhjny.com/var/file/product32.png',
                srcList9: [
                    'http://home.hzhjny.com/var/file/product32.png',
                ],


                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>