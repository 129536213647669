<template>
    <div class="backmain">
        <div>
            <img src="http://home.hzhjny.com/var/file/productback.jpg" alt="" class="bannerimg">
        </div>
        <div class="content">
            <el-row>
                <el-col :span="8" style="padding-right: 20px;">
                    <div class="imgmain">
                        <img src="http://home.hzhjny.com/var/file/product11.png" alt="" class="imgsize">
                        <div class="imgback">

                        </div>
                        <div class="imgtext">
                            导线精灵
                        </div>
                    </div>

                    <div class="prodetailborder">
                        <div>
                            导线精灵
                        </div>
                        <div class="prodesr">
                            导线精灵是一款集图像视频、导线测温、导线弧垂、覆冰等一体的在线监测装置。直接安装在输电线路导线上，内置AI处理器
                            ，可通过覆冰图像信息算出覆冰厚度，装置通过4G传输方式向中心站发送监测图像、视频、传感器和状态信息等数据。当相关信息超过设置的阈值后，后台将发送相关预警信息。
                        </div>
                        <div class="moretext" @click="lookmore1">
                            <div>
                                查看更多
                            </div>
                            <div>
                                <img src="http://home.hzhjny.com/var/file/jiantou.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8" style="padding-right: 20px;">
                    <div class="imgmain">
                        <img src="http://home.hzhjny.com/var/file/product10.png" alt="" class="imgsize">
                        <div class="imgback">

                        </div>
                        <div class="imgtext">
                            微拍监测装置-枪机式
                        </div>
                    </div>
                    <div class="prodetailborder">
                        <div>
                            微拍监测装置-枪机式
                        </div>
                        <div class="prodesr">
                            枪机式微拍监测装置是一款聚焦于解决输电线路杆塔双侧通道监控、塔基防外破及塔上设备互联为核心，集智能四摄、AI前置及超低功耗功能于一体的图像在线监控装置，支持定时自动抓拍和手动抓拍，支持20s短视频拍摄和传输。并具备AI识别功能，例如绝缘子、间隔棒
                            、防振锤等金具均可识别，也可对环境进行识别如塔吊, 挖掘机, 泵车等大型机械以及烟雾, 火点等安全隐患。
                        </div>
                        <div class="moretext" @click="lookmore2">
                            <div>
                                查看更多
                            </div>
                            <div>
                                <img src="http://home.hzhjny.com/var/file/jiantou.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8" style="padding-right: 20px;">
                    <div class="imgmain">
                        <img src="http://home.hzhjny.com/var/file/product12.png" alt="" class="imgsize">
                        <div class="imgback">

                        </div>
                        <div class="imgtext">
                            微拍监测装置-云台式
                        </div>
                    </div>
                    <div class="prodetailborder">
                        <div>
                            微拍监测装置-云台式
                        </div>
                        <div class="prodesr">
                            云台式微拍视频装置是集摄像、存储、传输、太阳能取电为一体的工业化、智能化、一体化设计的输电线路图像视频监测装置。其主要为了针对输电线廊区域
                            、塔基区域等周边环境实现全面的掌握，可利用云台特性根据需要进行预置位定时抓拍监控并实时调取视频数据，第一时间掌握现场情况。
                        </div>
                        <div class="moretext" @click="lookmore3">
                            <div>
                                查看更多
                            </div>
                            <div>
                                <img src="http://home.hzhjny.com/var/file/jiantou.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>


            <el-row style="margin-top: 30px;">
                <el-col :span="8" style="padding-right: 20px;">
                    <div class="imgmain">
                        <img src="http://home.hzhjny.com/var/file/product13.png" alt="" class="imgsize">
                        <div class="imgback">

                        </div>
                        <div class="imgtext">
                            三跨在线视频装置
                        </div>
                    </div>

                    <div class="prodetailborder">
                        <div>
                            三跨在线视频装置
                        </div>
                        <div class="prodesr">
                            输电线路分布区域广、传输距离长、容易受环境气候影响以及人们生活、生产活动的干扰。
                            对于“三跨"地区，由于涉及的范围广，对其安全防护更为重要。我们针对输电线路对关键点实时监控的需求，严格依据国家电网企业制定的相关标准，推出了输电线路三跨在线视频装置，为电力企业的安全生产保驾护航。
                        </div>
                        <div class="moretext" @click="lookmore4">
                            <div>
                                查看更多
                            </div>
                            <div>
                                <img src="http://home.hzhjny.com/var/file/jiantou.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8" style="padding-right: 20px;">
                    <div class="imgmain">
                        <img src="http://home.hzhjny.com/var/file/product14.png" alt="" class="imgsize">
                        <div class="imgback">

                        </div>
                        <div class="imgtext">
                            微气象监测装置
                        </div>
                    </div>
                    <div class="prodetailborder">
                        <div>
                            微气象监测装置
                        </div>
                        <div class="prodesr">
                            微气象在线监测系统是针对输电线路、变电站等在特殊地点的气象环境监测而设计的多要素微气象监测系统。可监测环境温度、湿度、风速、风向、气压、光辐射、降雨量等气象参数，
                            并将采集到的各种气象参数及其变化状况，通过GPRS无线网络实时的传送到系统主机中。
                        </div>
                        <div class="moretext" @click="lookmore5">
                            <div>
                                查看更多
                            </div>
                            <div>
                                <img src="http://home.hzhjny.com/var/file/jiantou.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8" style="padding-right: 20px;">
                    <div class="imgmain">
                        <img src="http://home.hzhjny.com/var/file/product25.png" alt="" class="imgsize">
                        <div class="imgback">

                        </div>
                        <div class="imgtext">
                            杆塔倾斜在线监测装置
                        </div>
                    </div>
                    <div class="prodetailborder">
                        <div>
                            杆塔倾斜在线监测装置
                        </div>
                        <div class="prodesr">
                            杆塔倾斜在线监测装置是一套基于高精度定位与多传感器融合的输电线路杆塔倾斜在线监测终端，能全天候监测杆塔倾斜情况、
                            晃动幅值与晃动轨迹数据，并能通过4G上报数据。使维护人员能够在后端查看现场数据，及时发现隐患、排除隐患，提高输电线路工作的可靠性。
                        </div>
                        <div class="moretext" @click="lookmore6">
                            <div>
                                查看更多
                            </div>
                            <div>
                                <img src="http://home.hzhjny.com/var/file/jiantou.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<style scoped>
    .bannerimg {
        width: 100%;
    }

    .backmain {
        width: 100%;
        background: white;
    }

    .content {
        width: 80%;
        margin: 0 auto;
        margin-top: 20px;
        padding-bottom: 30px;
        background: white;
    }

    .prodesr {
        font-size: 12px;
        line-height: 40px;
        color: #999;
        height: 30px;
        overflow: hidden;
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 10px;
    }

    .prodetailborder {
        height: 130px;
        padding: 20px 20px 10px;
        border: 1px solid #f2f2f2;
        box-sizing: border-box;

    }

    .moretext {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 14px;
        cursor: pointer;
        color: #777;
    }

    .imgsize {
        width: 80%;
        transition: 0.5s all;
        z-index: -1;
    }

    .imgmain {
        width: 100%;
        overflow: hidden;
        height: 300px;
        position: relative;
    }

    .imgback {
        position: absolute;
        width: 100%;
        height: 300px;
        background: black;
        left: 0;
        top: 0;
        opacity: 0;
        transition: 0.5s all;
    }

    .imgtext {
        position: absolute;
        width: 100%;
        height: 300px;
        top: 0;
        color: white;
        opacity: 0;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        transition: 0.5s all;
    }

    .imgmain:hover .imgsize {
        width: 100%;
    }

    .imgmain:hover .imgback {
        opacity: 0.5;
    }

    .imgmain:hover .imgtext {
        opacity: 1;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: ''
            }
        },
        methods: {
            lookmore1() {
                localStorage.setItem('productnum', 'product1')
                window.location.href = '/productdetail'
            },
            lookmore2() {
                localStorage.setItem('productnum', 'product2')
                window.location.href = '/productdetail'
            },
            lookmore3() {
                localStorage.setItem('productnum', 'product3')
                window.location.href = '/productdetail'
            },
            lookmore4() {
                localStorage.setItem('productnum', 'product4')
                window.location.href = '/productdetail'
            },
            lookmore5() {
                localStorage.setItem('productnum', 'product5')
                window.location.href = '/productdetail'
            },
            lookmore6() {
                localStorage.setItem('productnum', 'product6')
                window.location.href = '/productdetail'
            },
        }
    }
</script>