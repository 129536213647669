<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url9" :preview-src-list="srcList9">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">车载电力防外破报警系统</div>
                    <div class="detaildesr">
                        本随着城镇化建设的发展，大型施工项目越来越多。一些高空作业车、吊塔、吊车、起重机、消防车等大型设备因操作驾驶人员视野有限，如果不慎触碰到建筑周边的高压线，容易引发触电事故，造成巨大的损失。
                        此车载电力防外破报警系统能够实时探测、预警近线距离，帮助作业人员规避触电风险，避免触电。本产品分为两部分，包括无线工频电场传感器以及示警主机。无线工频电场传感器安装在吊车车臂上，示警主机安装在车辆驾驶舱内。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    本随着城镇化建设的发展，大型施工项目越来越多。一些高空作业车、吊塔、吊车、起重机、消防车等大型设备因操作驾驶人员视野有限，如果不慎触碰到建筑周边的高压线，容易引发触电事故，造成巨大的损失。
                    此车载电力防外破报警系统能够实时探测、预警近线距离，帮助作业人员规避触电风险，避免触电。本产品分为两部分，包括无线工频电场传感器以及示警主机。无线工频电场传感器安装在吊车车臂上，示警主机安装在车辆驾驶舱内。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr" style="text-indent: 0px;">
                    ·无接触式工频电场强度采集<br>
                    ·采用北斗定位+电场感应相结合的方式，提高报警准确性<br>
                    ·支持前后端互联，后端监控人员可以直接对前端人员施工作业进行安全把控，或者指导施工人员作业规范<br>
                    ·分级报警模式，依据电场强度等级预设不同的报警等级，比如设置不同电场强度阈值，将作业区域分为常规作业区域、加强警戒区域、危险警告区域等，并辅以不同语音或声光警示，也可以将主机直接定制成语音播报距离的模式。<br>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url9: 'http://home.hzhjny.com/var/file/product28.png',
                srcList9: [
                    'http://home.hzhjny.com/var/file/product28.png',
                ],


                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>