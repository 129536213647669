import VueRouter from 'vue-router'

import Unit from '../components/unit'
import Layout from '../components/Layout'
import About from '../components/aboutus'
import Callus from '../components/callus'
import Personrecruit from '../components/personrecruit'
import Detailperson from '../components/detailperson'
import Detailpersons from '../components/detailperson1'
import News from '../components/news'
import Product from '../components/product'
import Productdetail from '../components/productdetail'
import Newsdetail from '../components/newsdetail'
import Newsdetail1 from '../components/newsdetail1'
import Newsdetail2 from '../components/newsdetail2'

import sdproduct1 from '../components/sdproduct/sdproduct1'
import sdproduct2 from '../components/sdproduct/sdproduct2'
import sdproduct3 from '../components/sdproduct/sdproduct3'
import sdproduct4 from '../components/sdproduct/sdproduct4'
import sdproduct5 from '../components/sdproduct/sdproduct5'
import sdproduct6 from '../components/sdproduct/sdproduct6'
import sdproduct7 from '../components/sdproduct/sdproduct7'
import sdproduct8 from '../components/sdproduct/sdproduct8'
import sdproduct9 from '../components/sdproduct/sdproduct9'
import sdproduct10 from '../components/sdproduct/sdproduct10'
import sdproduct11 from '../components/sdproduct/sdproduct11'
import sdproduct12 from '../components/sdproduct/sdproduct12'
import sdproduct13 from '../components/sdproduct/sdproduct13'
import sdproduct14 from '../components/sdproduct/sdproduct14'
import sdproduct15 from '../components/sdproduct/sdproduct15'
import sdproduct16 from '../components/sdproduct/sdproduct16'
import sdproduct17 from '../components/sdproduct/sdproduct17'

import pdproduct1 from '../components/pdproduct/pdproduct1'
import pdproduct2 from '../components/pdproduct/pdproduct2'
import pdproduct3 from '../components/pdproduct/pdproduct3'
import pdproduct4 from '../components/pdproduct/pdproduct4'
import pdproduct5 from '../components/pdproduct/pdproduct5'

import bdproduct1 from '../components/bdproduct/bdproduct1'
import bdproduct2 from '../components/bdproduct/bdproduct2'
import bdproduct3 from '../components/bdproduct/bdproduct3'
import bdproduct4 from '../components/bdproduct/bdproduct4'

import tzproduct1 from '../components/tzproduct/tzproduct1'
import tzproduct2 from '../components/tzproduct/tzproduct2'
import tzproduct3 from '../components/tzproduct/tzproduct3'
import tzproduct4 from '../components/tzproduct/tzproduct4'
import tzproduct5 from '../components/tzproduct/tzproduct5'
import tzproduct6 from '../components/tzproduct/tzproduct6'
import tzproduct7 from '../components/tzproduct/tzproduct7'
import tzproduct8 from '../components/tzproduct/tzproduct8'
import tzproduct9 from '../components/tzproduct/tzproduct9'
import tzproduct10 from '../components/tzproduct/tzproduct10'

export default new VueRouter({
  mode: 'history',
  linkActiveClass: "active",
  routes: [
    {
      path: '',
      component: Layout,
      redirect: '/',
      hidden: true,
      children: [
        {
          path: '/',
          component: Unit
        },
        {
          path: '/unit',
          name: 'Unit',
          component: Unit
        },
        {
          path: '/about',
          name: 'About',
          component: About
        },
        {
          path: '/callus',
          name: 'Callus',
          component: Callus
        },
        {
          path: '/personrecruit',
          name: 'Personrecruit',
          component: Personrecruit
        },
        {
          path: '/detailperson',
          name: 'Detailperson',
          component: Detailperson
        },
        {
          path: '/detailperson1',
          name: 'Detailpersons',
          component: Detailpersons
        },
        {
          path: '/news',
          component: News
        },
        {
          path: '/product',
          component: Product
        },
        {
          path: '/productdetail',
          component: Productdetail,
          children:[
        {
          path: '/productdetail/sdproduct1',
          component: sdproduct1
        },
        {
          path: '/productdetail/sdproduct2',
          component: sdproduct2
        },
        {
          path: '/productdetail/sdproduct3',
          component: sdproduct3
        },
        {
          path: '/productdetail/sdproduct4',
          component: sdproduct4
        },
        {
          path: '/productdetail/sdproduct5',
          component: sdproduct5
        },
        {
          path: '/productdetail/sdproduct6',
          component: sdproduct6
        },
        {
          path: '/productdetail/sdproduct7',
          component: sdproduct7
        },
        {
          path: '/productdetail/sdproduct8',
          component: sdproduct8
        },
        {
          path: '/productdetail/sdproduct9',
          component: sdproduct9
        },
        {
          path: '/productdetail/sdproduct10',
          component: sdproduct10
        },
        {
          path: '/productdetail/sdproduct11',
          component: sdproduct11
        },
        {
          path: '/productdetail/sdproduct12',
          component: sdproduct12
        },
        {
          path: '/productdetail/sdproduct13',
          component: sdproduct13
        },
        {
          path: '/productdetail/sdproduct14',
          component: sdproduct14
        },
        {
          path: '/productdetail/sdproduct15',
          component: sdproduct15
        },
        {
          path: '/productdetail/sdproduct16',
          component: sdproduct16
        },
        {
          path: '/productdetail/sdproduct17',
          component: sdproduct17
        },
        {
          path: '/productdetail/pdproduct1',
          component: pdproduct1
        },
        {
          path: '/productdetail/pdproduct2',
          component: pdproduct2
        },
        {
          path: '/productdetail/pdproduct3',
          component: pdproduct3
        },
        {
          path: '/productdetail/pdproduct4',
          component: pdproduct4
        },
        {
          path: '/productdetail/pdproduct5',
          component: pdproduct5
        },
        {
          path: '/productdetail/bdproduct1',
          component: bdproduct1
        },
        {
          path: '/productdetail/bdproduct2',
          component: bdproduct2
        }
        ,
        {
          path: '/productdetail/bdproduct3',
          component: bdproduct3
        } ,
        {
          path: '/productdetail/bdproduct4',
          component: bdproduct4
        },
        {
          path: '/productdetail/tzproduct1',
          component: tzproduct1
        },
        {
          path: '/productdetail/tzproduct2',
          component: tzproduct2
        },
        {
          path: '/productdetail/tzproduct3',
          component: tzproduct3
        },
        {
          path: '/productdetail/tzproduct4',
          component: tzproduct4
        },
        {
          path: '/productdetail/tzproduct5',
          component: tzproduct5
        },
        {
          path: '/productdetail/tzproduct6',
          component: tzproduct6
        }
        ,
        {
          path: '/productdetail/tzproduct7',
          component: tzproduct7
        }  ,
        {
          path: '/productdetail/tzproduct8',
          component: tzproduct8
        } ,
        {
          path: '/productdetail/tzproduct9',
          component: tzproduct9
        } ,
        {
          path: '/productdetail/tzproduct10',
          component: tzproduct10
        }
          ]
        },
        {
          path: '/newsdetail',
          component: Newsdetail
        }
        ,
        {
          path: '/newsdetail1',
          component: Newsdetail1
        },
        {
          path: '/newsdetail2',
          component: Newsdetail2
        }
      ]
    }
  ]
})
