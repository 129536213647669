<template>
    <div class="backmain">
        <div>
            <img src="http://home.hzhjny.com/var/file/newsback.jpg" alt="" class="bannerimg">
        </div>
        <div class="content">
            <el-row>
                <el-col :span="8" style="padding-right: 20px;">
                    <div class="imgmain">
                        <img src="http://home.hzhjny.com/var/file/newsimg.jpg" alt="" class="imgsize">
                    </div>
                    <div class="prodetailborder">
                        <div class="protitdesr">
                            迎峰度冬电力供应有保障
                        </div>
                        <div class="prodesr">
                            随着北方地区陆续供暖，全社会用电量不断攀升，冬季电力保障工作受到广泛关注。国家能源局相关负责人介绍，预计迎峰度冬期间全国最高负荷较去年同期增加1.4亿千瓦，出现较大幅度增长。从目前掌握数据来看，迎峰度冬电力保供总体有保障，局部面临一定压力。
                        </div>
                        <div class="moretext" @click="newsdetail1">
                            <div>
                                查看更多
                            </div>
                            <div>
                                <img src="http://home.hzhjny.com/var/file/jiantou.png" alt="">
                            </div>
                        </div>
                    </div>

                </el-col>
                <el-col :span="8" style="padding-right: 20px;">
                    <div class="imgmain">
                        <img src="http://home.hzhjny.com/var/file/newsimg1.jpg" alt="" class="imgsize">
                    </div>
                    <div class="prodetailborder">
                        <div>
                            中国电力：创新引领未来能源
                        </div>
                        <div class="prodesr">
                            10月29日，中国电力面向全球成功发布6项科技创新成果，引领绿能新兴产业发展新潮流，进一步展现了其“打造科技创新引领型的世界一流绿色低碳能源供应商”的最新进展。
                        </div>
                        <div class="moretext" @click="newsdetail2">
                            <div>
                                查看更多
                            </div>
                            <div>
                                <img src="http://home.hzhjny.com/var/file/jiantou.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="imgmain">
                        <img src="http://home.hzhjny.com/var/file/product8.jpg" alt="" class="imgsize">
                    </div>
                    <div class="prodetailborder">
                        <div class="protitdesr">
                            国网浙江电力：技术标准推动新型电力系统省级示范区建设
                        </div>
                        <div class="prodesr">
                            2022年11月，国网浙江电力发布IEEE标准《电力领域知识图谱应用指南》，作为电力知识图谱领域首个国际标准，它定义了不同企业构建的电力知识图谱之间的兼容和协作规范，
                            为电力行业形成更加完整和准确的知识服务生态系统、实现可持续发展提供坚实基础。这是国网浙江电力技术标准化工作取得的又一项标志性成果。
                            电力是国民经济发展重要的基础性产业，它的发展关系到国民经济发展和人民生活水平的提高。电力企业作为国家支柱型企业，具有技术密集、资产密集特点，其独特的生产与经营方式决定了其信息化发展和两化融合的重要性。
                        </div>
                        <div class="moretext" @click="newsdetail">
                            <div>
                                查看更多
                            </div>
                            <div>
                                <img src="http://home.hzhjny.com/var/file/jiantou.png" alt="">
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<style scoped>
    .bannerimg {
        width: 100%;
    }

    .backmain {
        width: 100%;
        background: white;
    }

    .content {
        width: 70%;
        margin: 0 auto;
        margin-top: 20px;
        padding-bottom: 30px;
        background: white;
    }

    .protitdesr {
        font-size: 16px;
        line-height: 30px;
        height: 30px;
        overflow: hidden;
    }

    .prodesr {
        font-size: 12px;
        line-height: 40px;
        color: #999;
        height: 30px;
        overflow: hidden;
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 10px;
    }

    .prodetailborder {
        height: 130px;
        padding: 20px 20px 10px;
        border: 1px solid #f2f2f2;
        box-sizing: border-box;

    }

    .moretext {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 14px;
        cursor: pointer;
        color: #777;
    }

    .imgsize {
        width: 100%;
        transition: 0.5s all;
        z-index: -1;
    }

    .imgmain {
        width: 100%;
        overflow: hidden;
        height: 240px;
        position: relative;
        border-radius: 5px;
    }

    .imgback {
        position: absolute;
        width: 100%;
        height: 300px;
        background: black;
        left: 0;
        top: 0;
        opacity: 0;
        transition: 0.5s all;
    }

    .imgtext {
        position: absolute;
        width: 100%;
        height: 300px;
        top: 0;
        color: white;
        opacity: 0;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        transition: 0.5s all;
    }

    .imgmain:hover .imgsize {
        width: 110%;
    }

    .imgmain:hover .imgback {
        opacity: 0.5;
    }

    .imgmain:hover .imgtext {
        opacity: 1;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: ''
            }
        },
        methods: {
            newsdetail() {
                window.location.href = '/newsdetail'
            },

            newsdetail1() {
                window.location.href = '/newsdetail1'
            },

            newsdetail2() {
                window.location.href = '/newsdetail2'
            }
        },
    }
</script>