<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url1" :preview-src-list="srcList1">
                    </el-image>
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">导线精灵</div>
                    <div class="detaildesr">
                        导线精灵是一款集图像视频、导线测温、导线弧垂、覆冰等一体的在线监测装置。直接安装在输电线路导线上，内置AI处理器
                        ，可通过覆冰图像信息算出覆冰厚度，装置通过4G传输方式向中心站发送监测图像、视频、传感器和状态信息等数据。当相关信息超过设置的阈值后，后台将发送相关预警信息。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    导线精灵是一款集图像视频、导线测温、导线弧垂、覆冰等一体的在线监测装置。直接安装在输电线路导线上，内置AI处理器
                    ，可通过覆冰图像信息算出覆冰厚度，装置通过4G传输方式向中心站发送监测图像、视频、传感器和状态信息等数据。当相关信息超过设置的阈值后，后台将发送相关预警信息。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    供电可靠性高:采用高效感应取电技术， 取电功率大， 适合小负荷线路。 内置温湿度传感器，能够准确的测量导线附近的环境参数。
                    ·镜头防冻:独创的镜头加热技术，彻底杜绝了冬季镜头积冰的现象。 采用AI图像识别算法，计算导线覆冰厚度。
                </div>
            </el-tab-pane>
            <el-tab-pane label="参数" name="third">
                <el-descriptions class="margin-top" title="" :column="1" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            摄像性能
                        </template>
                        具备夜视功能的单体摄像头，200W像素。采用全彩黑光级低照度镜头，最低照度≤ <el-tag size="small">0.01Lux/F1.2</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            安装位置
                        </template>
                        可带电安装于导线上，装置和导线通过硅橡胶压紧紧固。外壳不和导线直接接触
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            供电方式
                        </template>
                        取能供电模块采用交流感应取电技术+锂电池+超级电容电源供电，长效稳定
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            工作方式
                        </template>
                        4G APN无线通信方式，与后台中心站或子站进行4G APN通信
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            抗干扰能力
                        </template>
                        满足输电线路环境下的强电场、强磁场的恶劣工作环境要求
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            数据采集
                        </template>
                        具备图像传输，定时取图，手动取图，导线表面温度，环境温度，导线弧垂，导线覆冰监测等功能
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            数据通信
                        </template>
                        配置4G APN通信模块，关键数据采用算法加密后再传输。通信方式满足电力专网自适应要求
                    </el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                size: 'medium',
                input1: '',
                input2: "",
                textarea2: '',
                url1: 'http://home.hzhjny.com/var/file/product11.png',
                srcList1: [
                    'http://home.hzhjny.com/var/file/product11.png',
                ],
                activeNames: 'first',
            }
        },
        methods: {
            newsdetail() {
                window.location.href = '/newsdetail'
            },

            newsdetail1() {
                window.location.href = '/newsdetail1'
            },

            newsdetail2() {
                window.location.href = '/newsdetail2'
            }
        },
    }
</script>