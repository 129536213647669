import { render, staticRenderFns } from "./pdproduct2.vue?vue&type=template&id=4135227d&scoped=true"
import script from "./pdproduct2.vue?vue&type=script&lang=js"
export * from "./pdproduct2.vue?vue&type=script&lang=js"
import style0 from "./pdproduct2.vue?vue&type=style&index=0&id=4135227d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4135227d",
  null
  
)

export default component.exports