<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url5" :preview-src-list="srcList5">
                    </el-image>
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">分布式光纤振动防外破系统</div>
                    <div class="detaildesr">
                        电缆由于其本身的敷设特性，分布较广，监测不便，其受外力破坏风险点越来越多，各项大型迁改工程的施工，容易造成周边的电缆受损。
                        我司自主研发的分布式光纤振动防外破系统可以实现对地埋电缆运行状态的实时振动监测，能及时有效的了解现场环境振动状态，防患于未然。该系统可以实现24小时实时监测，可节约人工巡检，同时解决对难以监测的位置的难点，填补监测的盲区；如果发生电缆上方出现开挖或施工破坏等异常情况，能有效的定位报警，指导工作人员进行定位检修，节约时间，提高检修效率。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    电缆由于其本身的敷设特性，分布较广，监测不便，其受外力破坏风险点越来越多，各项大型迁改工程的施工，容易造成周边的电缆受损。我司自主研发的分布式光纤振动防外破系统可以实现对地埋电缆运行状态的实时振动监测，能及时有效的了解现场环境振动状态，防患于未然。该系统可以实现24小时实时监测，可节约人工巡检，同时解决对难以监测的位置的难点，填补监测的盲区；
                    如果发生电缆上方出现开挖或施工破坏等异常情况，能有效的定位报警，指导工作人员进行定位检修，节约时间，提高检修效率。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr" style="text-indent: 0px;">
                    ·探测灵敏：采用光相位解调技术，可高保真还原振动源信号；<br>
                    ·高性噪比：性噪比达40倍（普通振动探测系统性噪比10-15倍）<br>
                    ·距离检测：单/双通道设计，监测距离长，可达数十公里；<br>
                    ·精准定位：定位精度高，支持同时监测定位多点振动事件；<br>
                    ·系统联动：支持视频、声光报警器等外部设备联动。
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url5: 'http://home.hzhjny.com/var/file/product44.png',
                srcList5: [
                    'http://home.hzhjny.com/var/file/product44.png',
                ],



                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>