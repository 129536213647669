<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url5" :preview-src-list="srcList5">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">绝缘子污秽在线监测装置</div>
                    <div class="detaildesr">
                        输电线路绝缘子污秽在线监测装置主要由数据监测终端和数据监测中心两部分组成，能够检测绝缘子外绝缘表面污秽状况、并对污闪问题进行准确预报。数据监测终端安装在输电线路杆塔或变电站绝缘子附近，完成对现场污秽物（盐密、灰密）、温度、湿度的实时监测。监测数据通过GSM无线网络以短信方式，向监测中心发送。
                        数据监测中心完成对监测数据的转换和处理。当数据出现异常情况时，系统会以多种方式发出报警信息，提示相关人员作出相应的措施。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    输电线路绝缘子污秽在线监测装置主要由数据监测终端和数据监测中心两部分组成，能够检测绝缘子外绝缘表面污秽状况、并对污闪问题进行准确预报。数据监测终端安装在输电线路杆塔或变电站绝缘子附近，完成对现场污秽物（盐密、灰密）、温度、湿度的实时监测。监测数据通过GSM无线网络以短信方式，向监测中心发送。
                    数据监测中心完成对监测数据的转换和处理。当数据出现异常情况时，系统会以多种方式发出报警信息，提示相关人员作出相应的措施。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    具备传感、采集现场盐密、灰密、气温、相对湿度等功能，进行相应存储，并将测量结果通过通信网络传输到状态监测系统；具备自动采集功能，按设定时间间隔自动采集盐密、灰密、气温、相对湿度，最大采样间隔
                    不大于24小时；
                    同时具备自动采集方式与受控采集方式，能响应远程指令，按设置采集方式、自动采集时间、采集时间间隔启动采集。
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url5: 'http://home.hzhjny.com/var/file/product39.png',
                srcList5: [
                    'http://home.hzhjny.com/var/file/product39.png',
                ],



                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>