<template>
    <div class="backmain">
        <div>
            <img src="http://home.hzhjny.com/var/file/personback.jpg" alt="" class="bannerimg">
        </div>
        <div class="content">
            <router-link to="/detailperson">
                <div class="personline">
                    <div class="persontitle">
                        硬件工程师
                    </div>
                    <div class="describe">
                        招聘岗位：硬件工程师
                        1、电子元器件选型，电子原理图绘制及PCB布板；
                        2、样品板的焊接调试及客户现场返回的问题进行定位分析；
                        3、协助软件工程师联调程序并给出相应的建议；
                        4、解决相应的硬件问题及调试问题。
                        【职位要求】
                        1、本科及以上学历，电子类或通信类专业，英语四级及以上，1年以上仪表、终端等硬件开发经验，；或3年以上其它行业硬件开发经验；有低功耗设计经验优先；
                        2、熟悉数字、模拟电路设计、高速信号、电子元器件测试；
                        3、可独立进线硬件原理图电路设计，能独立进行2层、4层板PCB Layout；
                        4、熟悉常用电子原件关键参数，了解电磁兼容，熟练使用Altium Designer/Cadence等工具；
                        5、熟悉ST系列、兆易创新系列、NXP系列及单片机外围器件；
                        6、可熟练使用硬件测试设备，可独立排查、定位、修复硬件相关产品问题
                        7、熟悉终端类产品形式规范等，有电力相关产品设计经验优先。
                    </div>
                    <div class="lookmore">
                        点击查看更多 →
                    </div>
                </div>
            </router-link>
            <router-link to="/detailperson1">
                <div class="otherline">
                    <div class="datemain">
                        <div class="dateday">
                            24
                        </div>
                        <div class="dateyear">
                            2021-05
                        </div>
                    </div>
                    <div class="otherright">
                        <div class="postname">
                            嵌入式开发工程师
                        </div>
                        <div class="fabutime">
                            <i class="el-icon-time"></i>
                            发布时间：2021-05-24
                        </div>
                        <div class="describes">
                            1、负责项目中嵌入式程序设计、代码编写及测试；
                            2、负责相关技术文档的撰写和维护；
                            任职要求：
                            1、大专及以上学历，电子、计算机、通信、自动化类相关专业；
                            2、具有1年以上物联网网关开发与设计经验，熟悉硬件；
                            3、精通C/C++等编程语言，有STM32、MSP430、ARM9等两种以上嵌入式系统开发经验；
                            4、了解NB-IOT，熟悉NB模块；
                            5、熟悉计算机通讯接口，有RS232通讯和RS485通讯方式的运用经验；
                            6、具备一定的硬件知识，能读懂电路图，有较好的设计文档编写经验；
                            7、具备良好的沟通能力、团队协作能力和认真积极的工作态度。
                        </div>
                    </div>
                    <div class="circleright">
                        <img src="http://home.hzhjny.com/var/file/right1.png" alt="" class="rightimg1">
                        <img src="http://home.hzhjny.com/var/file/right2.png" alt="" class="rightimg2">
                    </div>
                </div>
            </router-link>

        </div>
    </div>
</template>

<style scoped>
    .bannerimg {
        width: 100%;
    }

    .backmain {
        width: 100%;
        background: white;
    }

    .content {
        width: 80%;
        margin: 0 auto;
        margin-top: 20px;
        padding-bottom: 30px;
        background: white;
    }

    .personline {
        padding: 20px;
        width: 100%;
        background: #fafafa;
        margin-bottom: 40px;
        cursor: pointer;
    }

    .persontitle {
        font-weight: bold;
        color: #e7463c;
        margin-bottom: 5px;
        font-size: 18px;
    }

    .lookmore {
        display: flex;
        float: none;
        align-items: center;
        font-size: 14px;
        justify-content: center;
        width: 130px;
        height: 30px;
        padding: 0px;
        color: #e7463c;
        line-height: 30px;
        border: 1px solid #e7463c;
        transition: all 0.3s ease;
        cursor: pointer;
        margin-top: 10px;
    }

    .lookmore:hover {
        background: #e7463c;
        color: white;
    }

    .describe {
        font-size: 14px;
        width: 80%;
        line-height: 30px;
        height: 60px;
        overflow: hidden;
    }

    .describes {
        font-size: 14px;
        width: 100%;
        line-height: 30px;
        height: 60px;
        overflow: hidden;
    }

    .datemain {
        width: 80px;
        height: 98px;
        background: #f5f5f5;
        text-align: center;
        font-family: arial;
        transition: all .5s ease;
    }

    .dateday {
        font-size: 44px;
        line-height: 46px;
        color: #e7463c;
        padding-top: 15px;
    }

    .dateyear {
        color: #e7463c;
    }

    .otherline {
        padding-bottom: 25px;
        border-bottom: 1px solid #eaeaea;
        transition: all .5s ease;
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 85%;
    }

    .otherline:hover {
        padding-bottom: 35px;
    }

    .otherline:hover .dateday {
        color: white;
    }

    .otherline:hover .datemain {
        background: #e7463c;
    }

    .otherline:hover .dateyear {
        color: white;
    }

    .otherright {
        margin-left: 30px;
        flex: 1;
    }

    .fabutime {
        color: #999;
        font-size: 12px;
        line-height: 30px;
    }

    .otherline:hover .postname {
        color: #e7463c;
    }

    .circleright {
        margin-left: 40px;
    }

    .rightimg1 {
        display: block;
        transition: all .5s ease;
    }

    .rightimg2 {
        display: none;
        transition: all .5s ease;
    }

    .otherline:hover .rightimg1 {
        display: none;

    }

    .otherline:hover .rightimg2 {
        display: block;
    }
</style>

<script>
    export default {
        data() {
            return {

            };
        },
        methods: {


        }
    };
</script>