<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url6" :preview-src-list="srcList6">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">双光谱云台测温热像仪</div>
                    <div class="detaildesr">
                        双光谱云台测温热像仪设备集成了可见光和热成像功能，主要基于红外热辐射原理进行开发，是一种非接触、实时、连续的精确测温设备。
                        该设备能够通过精确测温感知目标状态的变化，同时结合设备的软件系统可对测温对象的温度信息进行数据可视化展示。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    双光谱云台测温热像仪设备集成了可见光和热成像功能，主要基于红外热辐射原理进行开发，是一种非接触、实时、连续的精确测温设备。
                    该设备能够通过精确测温感知目标状态的变化，同时结合设备的软件系统可对测温对象的温度信息进行数据可视化展示。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    360度云台控制，可自定义进行无死角巡视，支持水平和垂直方向巡航扫描。设备具备监控画面稳定、测温精确、定位精度高、功耗低等优点，
                    通过结合设备的软件系统可对测温对象的温度信息进行数据可视化展示，可以安装在巡检式或轨道式机器人上，可广泛应用于管廊巡检、变电站、开关室等各类控制室等场景中。
                </div>
            </el-tab-pane>
            <el-tab-pane label="参数" name="third">
                <el-descriptions class="margin-top" title="" :column="2" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            传感器类型
                        </template>
                        非制冷型探测器
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            传感器像素
                        </template>
                        640 x 480
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            摄像头分辨率
                        </template>
                        <el-tag size="small">800W</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            帧率
                        </template>
                        25FPS
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            响应波段
                        </template>
                        8~14μm
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            镜头焦距
                        </template>
                        4/7/15mm
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            分辨率
                        </template>
                        1920x1080
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            镜头
                        </template>
                        5.5~180mm，33倍光学变倍
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            最低照度
                        </template>
                        彩色:0.001Lux
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            水平旋转角度
                        </template>
                        0～360°连续旋转
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            垂直旋转角度
                        </template>
                        -90°～+90°
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            预置位精度
                        </template>
                        0.01°～15°/S
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            成像器件
                        </template>
                        200万 1/2.8”CMOS
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            温度曲线
                        </template>
                        自动生成不同测温区域温度曲线
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            抗干扰能力
                        </template>
                        满足各类环境下的强电场、强磁场的恶劣工作环境要求。
                    </el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url6: 'http://home.hzhjny.com/var/file/product16.png',
                srcList6: [
                    'http://home.hzhjny.com/var/file/product16.png',
                ],

                activeNames: 'first',
            }
        },
        methods: {
 
        },
    }
</script>