<template>
  <div id="layout">
    <el-container>
      <el-header style="height:80px">
        <Header></Header>
      </el-header>
      <el-container class="tableleft">
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
      <el-footer>
        <Footer>

        </Footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
  import Header from "./Header.vue";
  import Footer from "./Footer.vue";
  export default {
    components: {
      Header,
      Footer
      // HelloWorld,
      // Home
    },
    data() {
      return {

      };
    },
    created() {

    },
    mounted() {

    },
    methods: {

    }
  };
</script>

<style scoped>
  a {
    text-decoration: none;
  }

  .el-main {
    padding-top: 0;
  }



  .el-header {
    padding: 0;
  }

  .el-aside {
    min-height: 90vh;
    background: white;
  }

  .el-card__header {
    padding: 20px 20px 10px 20px;
  }

  .el-menu-item {
    font-size: 16px;
    padding: 0 !important;
    text-align: center;
  }
</style>