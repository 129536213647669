<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url9" :preview-src-list="srcList9">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">电缆接头温度及局放测量装置</div>
                    <div class="detaildesr">
                        电力电缆已经成为输送电力的血管之一，而高压电力电缆接头又是电力电缆系统中的薄弱环节，容易发生故障。因此对电缆进行现场局部放电监测、温度监测对电力系统的安全运行重要的意义。本系统采用模块化设计通过安装电容耦合传感器，
                        来耦合电缆接头的局部放电脉冲电流信号，耦合到的脉冲信号通过同轴电缆传送至监测装置，对模拟信号经过放大、模拟数字转换后变成数据信号。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    电力电缆已经成为输送电力的血管之一，而高压电力电缆接头又是电力电缆系统中的薄弱环节，容易发生故障。因此对电缆进行现场局部放电监测、温度监测对电力系统的安全运行重要的意义。本系统采用模块化设计通过安装电容耦合传感器，
                    来耦合电缆接头的局部放电脉冲电流信号，耦合到的脉冲信号通过同轴电缆传送至监测装置，对模拟信号经过放大、模拟数字转换后变成数据信号。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr" style="text-indent: 0px;">
                    ·自动分析，对比典型图谱，及时发现异常后报警；<br>
                    ·采用虚拟仪器技术软件作为采集和分析软件的开发平台，保证抗干扰技术的良好实现；<br>
                    ·系统软件可分为参数设定、数据采集、抗干扰处理、谱图分析、趋势分析、数据整理及报表等部分，直观可靠；<br>
                    ·可根据需求定制安装方案。
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url9: 'http://home.hzhjny.com/var/file/product24.png',
                srcList9: [
                    'http://home.hzhjny.com/var/file/product24.png',
                ],


                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>