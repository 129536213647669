<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url8" :preview-src-list="srcList8">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">智慧地钉</div>
                    <div class="detaildesr">
                        智慧地钉是通过内置的高精度多功能振动传感器采集数据，可实时感知路面的振动压力并且根据振动压力存在的区别，判断并检测电缆通道两侧十几米范围内的重型器械施工情况。内含高速率、高性能芯片，
                        具有优异的AI
                        自学习算法。具备AI自学习周期，通过各设备间信息交互、数据库存对比分析、人工干预等实现对外破机械施工特性的精准锁定，并可以自动过滤车辆通行共振带来的误报警。
                        相比于传统的智慧地钉，2.0版本在原有的基础上增加了地下电缆和设备信息融合，实现底线电缆的精准定位；增加了管廊线路的线路走线区域化警戒，大幅减少因周边施工带来的误报；同时具备线路巡线功能。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    智慧地钉是通过内置的高精度多功能振动传感器采集数据，可实时感知路面的振动压力并且根据振动压力存在的区别，判断并检测电缆通道两侧十几米范围内的重型器械施工情况。内含高速率、高性能芯片，
                    具有优异的AI
                    自学习算法。具备AI自学习周期，通过各设备间信息交互、数据库存对比分析、人工干预等实现对外破机械施工特性的精准锁定，并可以自动过滤车辆通行共振带来的误报警。
                    相比于传统的智慧地钉，2.0版本在原有的基础上增加了地下电缆和设备信息融合，实现底线电缆的精准定位；增加了管廊线路的线路走线区域化警戒，大幅减少因周边施工带来的误报；同时具备线路巡线功能。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr" style="text-indent: 0px;">
                    ·监测的数据通过NB-LOT或无线4G进行传输，无需布线；<br>
                    ·超低功耗设计，电池使用寿命>5年，后期维护成本低；<br>
                    ·产品本地达到IP68防护等级；<br>
                    ·专业工具，安装便捷，可结合AI智能图像装置，实现图像和数据的协调判断。
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                size: 'medium',
                textarea2: '',
                url8: 'http://home.hzhjny.com/var/file/product18.png',
                srcList8: [
                    'http://home.hzhjny.com/var/file/product18.png',
                ],


                activeNames: 'first',
            }
        },
        methods: {
            newsdetail() {
                window.location.href = '/newsdetail'
            },

            newsdetail1() {
                window.location.href = '/newsdetail1'
            },

            newsdetail2() {
                window.location.href = '/newsdetail2'
            }
        },
    }
</script>