<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url5" :preview-src-list="srcList5">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">架空线路分布式故障定位监测装置</div>
                    <div class="detaildesr">
                        一旦输电线路上发生故障，须尽快找到故障点，排除故障，恢复供电。但是，由于高压和超高压输电线路往往暴露在不同的环境并分布在广大的地理区域，运行环境恶劣。针对此种情况，输电线路分布式故障定位监测装置在线路故障后迅速准确找到故障点，
                        不仅对及时修复线路和快速恢复供电，而且对整个电力系统的安全稳定和经济运行都有十分重要的作用。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    一旦输电线路上发生故障，须尽快找到故障点，排除故障，恢复供电。但是，由于高压和超高压输电线路往往暴露在不同的环境并分布在广大的地理区域，运行环境恶劣。针对此种情况，输电线路分布式故障定位监测装置在线路故障后迅速准确找到故障点，
                    不仅对及时修复线路和快速恢复供电，而且对整个电力系统的安全稳定和经济运行都有十分重要的作用。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    输电线路分布式故障定位监测装置采用了分布式行波测量技术，监测终端分布式安装在输电方式发送回后台数据中心，经数据中心综合分析，可以根据不同的故障特征迅速准确地判定故障点，提高故障定位精度从而提高故障巡线效率，而且还能够捕捉暂态故障行波波形，对故障是雷击故障还是非雷击故障、雷击故障属绕击还是反击进行智能辨识。
                    诊断结果以web和短信两种方式展示，发送给用户，有助于及时修复故障线路，确保整个电网的安全稳定运行。
                </div>
            </el-tab-pane>
            <el-tab-pane label="参数" name="third">
                <el-descriptions class="margin-top" title="" :column="2" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            最大检测线路长度
                        </template>
                        30KM
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            行波传感器宽带
                        </template>
                        1kHz~2MHz
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            行波电流连续记录时长
                        </template>
                        ≥1000μs
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            行波采样率
                        </template>
                        ≥5MHz
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            单导线故障电流测量范围
                        </template>
                        10A～15000A
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            垂直视场角
                        </template>
                        44°
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions class="margin-top" title="" :column="1" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            故障定位传感器
                        </template>
                        区间定位可靠性≥99％；定位误差≤100米
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            供电时长
                        </template>
                        持续无光照可以维持装置正常运行15天的供电要求
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            故障原因辨识
                        </template>
                        能辨识雷击故障与非雷击故障，雷击与非雷击故障辨识准确率＞95％
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            通信方式
                        </template>
                        全网通4G／5G／WIFI无线传输
                    </el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url5: 'http://home.hzhjny.com/var/file/product37.png',
                srcList5: [
                    'http://home.hzhjny.com/var/file/product37.png',
                ],



                activeNames: 'first',
            }
        },
        methods: {
            newsdetail() {
                window.location.href = '/newsdetail'
            },

            newsdetail1() {
                window.location.href = '/newsdetail1'
            },

            newsdetail2() {
                window.location.href = '/newsdetail2'
            }
        },
    }
</script>