<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url3" :preview-src-list="srcList3">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product13.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">三跨在线视频装置</div>
                    <div class="detaildesr">
                        输电线路分布区域广、传输距离长、容易受环境气候影响以及人们生活、生产活动的干扰。
                        对于“三跨"地区，由于涉及的范围广，对其安全防护更为重要。我们针对输电线路对关键点实时监控的需求，严格依据国家电网企业制定的相关标准，推出了输电线路三跨在线视频装置，为电力企业的安全生产保驾护航。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    输电线路分布区域广、传输距离长、容易受环境气候影响以及人们生活、生产活动的干扰。
                    对于“三跨"地区，由于涉及的范围广，对其安全防护更为重要。我们针对输电线路对关键点实时监控的需求，严格依据国家电网企业制定的相关标准，推出了输电线路三跨在线视频装置，为电力企业的安全生产保驾护航。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    自持动态调整分辨率与顿率，并根据网络情况实现动态调整并回传;支持夜间功能，满足夜间监控需要，夜间功能是否开启可通过远程控制，可与国家电网公司电网统一视频平台做接口对接;支持前端24小时连续录像功能，录像采用循环疆盖方式(即存储空间录满后，自动覆盖早期录像文件)存储容量可满足5天及以上历史录像要求)；
                    支持云台控制功能，云台支持上、下、左、右移动及步长、速度等控制，镜头支持变倍调节控制；智能识别动态物体入侵发出告警信息，并启动录像或者拍照功能，相关影像资料至少留存48小时。
                </div>
            </el-tab-pane>
            <el-tab-pane label="参数" name="third">
                <el-descriptions class="margin-top" title="" :column="2" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            视频分辨率
                        </template>
                        <el-tag size="small">1080P</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            视频编码
                        </template>
                        支持H264编码
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            最低照度
                        </template>
                        ＜0.01lux/f1.2;
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            行波采样率
                        </template>
                        ＞100米
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            激光功率
                        </template>
                        <el-tag size="small">500mW</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            变焦率
                        </template>
                        2光学20倍
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            云台水平旋转角度
                        </template>
                        0°~360° (球机)
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            云台俯仰角度
                        </template>
                        0°~90°(视频拍摄0°~90°)
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            存储容量
                        </template>
                        大于5天
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            供电方式
                        </template>
                        可采用市电、太阳能供电、风光互补供电、地线感应供电
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            通讯性能
                        </template>
                        配置4G APN通信模块，关键数据采用算法加密后再传输。通信方式满足电力专网自适应要求
                    </el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                size: 'medium',
                input1: '',
                input2: "",
                textarea2: '',
                url3: 'http://home.hzhjny.com/var/file/product13.png',
                srcList3: [
                    'http://home.hzhjny.com/var/file/product13.png',
                ],

                activeNames: 'first',
            }
        },
        methods: {
            newsdetail() {
                window.location.href = '/newsdetail'
            },

            newsdetail1() {
                window.location.href = '/newsdetail1'
            },

            newsdetail2() {
                window.location.href = '/newsdetail2'
            }
        },
    }
</script>