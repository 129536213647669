<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url9" :preview-src-list="srcList9">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">无线太阳能红外监测系统</div>
                    <div class="detaildesr">
                        无线太阳能红外监测系统是一种小型，自取电的红外探测监测系统。具备精确测温、清晰成像、简单易用、轻巧便携等优点，
                        可以帮助现场作业人员快速判断环境中的高、低温情况，诊断电气和机械故障，支持一键抓拍与录像功能，适用于变电，电缆接头等场景。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    无线太阳能红外监测系统是一种小型，自取电的红外探测监测系统。具备精确测温、清晰成像、简单易用、轻巧便携等优点，
                    可以帮助现场作业人员快速判断环境中的高、低温情况，诊断电气和机械故障，支持一键抓拍与录像功能，适用于变电，电缆接头等场景。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr" style="text-indent: 0px;">
                    ·无源无线: 无需外电，三面太阳能板独立供电，支持4G无线图像传输；<br>
                    ·一体式铝合金壳体，IP66防护等级，适应各种野外恶劣环境；<br>
                    ·功能丰富，具有定时拍摄、边缘计算、报警、本机存储、云端备份、趋势展示等实用功能；<br>
                    ·轻巧便携:系统部署简单，易于携带。
                </div>
            </el-tab-pane>
            <el-tab-pane label="参数" name="third">
                <el-descriptions class="margin-top" title="" :column="2" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            传感器类型
                        </template>
                        非制冷型探测器
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            传感器像素
                        </template>
                        640 x 480
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            温度分辨率
                        </template>
                        ＜0.06C(@30°C)
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            调焦方式
                        </template>
                        电动调焦
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            响应波段
                        </template>
                        8~14μm
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            报警方式
                        </template>
                        短信报警
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            报警条件
                        </template>
                        报警触发条件可配置
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            最大功耗
                        </template>
                        3W
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            最低照度
                        </template>
                        彩色:0.001Lux
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            待机功耗
                        </template>
                        0.1W
                    </el-descriptions-item>

                </el-descriptions>
                <el-descriptions class="margin-top" title="" :column="1" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            图像传输方式
                        </template>
                        通过4G实时或定时回传至手机APP或云平台
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            供电方式
                        </template>
                        太阳能充电+蓄电池 (无需外接电源供电)
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            工作模式
                        </template>
                        唤醒工作时长、自动休眠时长可设置
                    </el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url9: 'http://home.hzhjny.com/var/file/product21.png',
                srcList9: [
                    'http://home.hzhjny.com/var/file/product21.png',
                ],


                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>