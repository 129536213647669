<template>
    <div class="backmain">
        <div>
            <img src="http://home.hzhjny.com/var/file/newsback.jpg" alt="" class="bannerimg">
        </div>
        <div class="content">
            <div class="newstitle">
                迎峰度冬电力供应有保障
            </div>
            <div style="text-align: center;color: #999;font-size: 14px;">
                2023-11-09 10:23 来源： 中国发展网
            </div>
            <hr>
            <div class="imgmain">
                <img src="http://home.hzhjny.com/var/file/newsimg.jpg" alt="" class="imgsize">
            </div>
            <div class="newscont">
                随着北方地区陆续供暖，全社会用电量不断攀升，冬季电力保障工作受到广泛关注。国家能源局相关负责人介绍，预计迎峰度冬期间全国最高负荷较去年同期增加1.4亿千瓦，出现较大幅度增长。从目前掌握数据来看，迎峰度冬电力保供总体有保障，局部面临一定压力。
            </div>
            <div class="fonttitle">
                需求增长加快
            </div>
            <div class="newscont">
                今夏，全国最大负荷和日发电量均创历史新高，较去年峰值高出约5000万千瓦、15亿千瓦时。度夏期间，江苏、浙江、广东等24个省级电网负荷累计80多次创历史新高。因此，今年电力迎峰度冬也颇受关注。
            </div>
            <div class="newscont">
                国家能源局新闻发言人张星分析说，“下半年以来，电力需求同比增长加快，9月全社会用电量同比增长9.9%。预计迎峰度冬期间全国电力供应总体有保障，但是西北、华东、西南、南方区域的部分省份电力保供压力较大，其中云南存在一定电量缺口，蒙西可能存在一定电力缺口。此外，极端天气、主要流域来水和部分地区燃料保障存在一定不确定性”。
            </div>
            <div class="newscont">
                虽然存在不确定性，但从基本面看，今年迎峰度冬具备较好保供基础。中国电力企业联合会表示，当前电煤库存整体处于历史同期较好水平，水电蓄能情况也好于上年同期，综合用电需求和电力供应能力分析，预计迎峰度冬期间全国电力供需形势总体平衡。电力供需形势偏紧的主要是华东、西南和南方区域中的部分省级电网。在充分发挥大电网资源优化配置、开展余缺互济后，电力缺口基本可以消除。
            </div>
            <div class="newscont">
                中国电煤采购价格指数（CECI）编制办公室发布的《CECI周报》（2023年39期）显示，电煤耗量和入厂煤量均环比回升，电厂库存增长明显。10月19日，纳入统计的发电企业煤炭库存可用天数27天，周环比升高1.3天。
            </div>
            <div class="newscont">
                煤炭运输方面，10月19日，大秦铁路完成秋季集中检修施工。此次集中检修提前4天完成，运力快速恢复，为今冬明春电煤保供夯实了基础。随着集中检修施工结束，大秦铁路日运量将快速回升至120万吨以上。
            </div>
            <div class="fonttitle">
                煤电仍是主力
            </div>
            <div class="newscont">
                电网一头连着生产，一头连着消费，是电力保供的核心枢纽。11月2日，在安徽省淮南市大通区洛河镇，一架无人机正在10千伏泉马21线附近，按照既定航线巡视。同时，国网淮南供电公司配网运检人员正在管控中心办公室内，对无人机巡检数据进行分析比对，查看设备运行状况。
            </div>
            <div class="newscont">
                “通过开展无人机自适应巡检，作业人员仅需半小时即可上手，大幅缩短巡检周期，全面提升巡检质效。”国网淮南供电公司运维检修部副主任石磊介绍，公司通过人巡+自适应巡检等方式，全面开展主配网设备诊断性特巡，集中力量做好重点设备检修，全力保障迎峰度冬电力供应安全稳定。
            </div>
            <div class="newscont">
                面对电力供需不确定性，提早谋划部署是关键。张星表示，要多措并举保障电力安全稳定供应。要做好设备运维检修，推动电源电网项目加快建设，用好支持政策，强化要素保障，按计划抓煤电项目进度和开工投产；充分发挥大电网统筹配置能力，深挖西北、南方等区域余缺互济潜力，推动解决省间电力交易惜售等问题。
            </div>
            <div class="newscont">
                煤电仍是保供主力。专家表示，发电企业应达到高库存水平进入迎峰度冬高耗量阶段，以应对雨雪天气对运输影响造成阶段性供应量不足。同时，有关部门要加强中长期合同履约情况监管，提高长协煤兑现，引导中长期合同履约的煤质提升。
            </div>
            <div class="newscont">
                迎峰度冬一大重点是供暖。中国电力企业联合会建议，抓住供热季前窗口期，全力推进老旧设备和管网治理工作，提高设备和管网可靠性。此外，要在燃料供应的价格上，给予供热机组一定的政策性保障与优惠。
            </div>

            <div class="fonttitle">
                新能源有新担当
            </div>

            <div class="newscont">
                国家能源局新能源和可再生能源司副司长王大鹏介绍，今年前三季度，全国可再生能源新增装机1.72亿千瓦，同比增长93%，占新增装机的76%。全国可再生能源发电量达2.07万亿千瓦时，约占全部发电量的31.3%。其中，风电光伏发电量达1.07万亿千瓦时，同比增长22.3%，超过了同期城乡居民生活用电量。
                当下，绿色是发展的主色调，聚焦于此，国网浙江电力技术标准支撑更加有力。2022年以来，该公司建立健全碳达峰、碳中和标准，实施碳达峰、碳中和标准化提升工程，开展“基于新能源云平台的碳中和支撑服务”新型电力系统示范项目建设，致力于推动能源领域保供稳价和绿色转型。
            </div>

            <div class="newscont">
                随着新能源装机跨越式增长，新能源发电量占比不断提升。如何用好新能源，真正发挥其电力保供作用是关键。近日，国家发展改革委、国家能源局发布关于加强新形势下电力系统稳定工作的指导意见。其中提到，大力提升新能源主动支撑能力。推动系统友好型电站建设，有序推动储能与可再生能源协同发展，逐步实现新能源对传统能源的可靠替代。
            </div>

            <div class="newscont">
                在浙江省衢州市，为保障冬季电力供应平稳有序，电力部门结合地区季节性负荷特点、电煤燃气供应、水电及新能源发电预测，动态发布地区供需平衡信息，及时调整电网负荷预测和运行方式。
            </div>
            <div class="newscont">
                “我们密切关注水电站水位、流量、降水等信息，做好顶峰、生活、农灌的合理调配，提前蓄水，上线试运行水光储余缺互济智能柔性精准调控系统，运用新技术，优化配置水电、光伏、储能等多种调节资源，完成300余座、超120万千瓦资源的聚合，实现水电、光伏、储能一体化运行调控，协调互济能力由10万千瓦提升至20万千瓦，最大限度拓宽电网调节潜力。”国网衢州供电公司调控中心主任周慧忠说。
            </div>
            <div class="newscont">
                中国电力企业联合会表示，推动新能源快速发展，要结合地方消纳形势、网架结构、负荷特性、电源结构、新能源发展等因素，科学滚动测算确定新能源配置储能的比例、规模，统一调度、共享使用，最大程度发挥储能促进新能源消纳、调峰调频、功率支撑等多重作用。
            </div>
            <hr>
            <div class="bottomline">
                <div class="posttexttype">
                    上一个：无
                </div>
                <div class="posttexttype" style="cursor: pointer;" @click="nextperson">
                    下一个：中国电力：创新引领未来能源
                </div>
            </div>
        </div>

    </div>
</template>

<style scoped>
    .bannerimg {
        width: 100%;
    }

    .backmain {
        width: 100%;
        background: white;
    }

    .content {
        width: 70%;
        margin: 0 auto;
        margin-top: 20px;
        padding-bottom: 30px;
        background: white;
    }

    .imgmain {
        text-align: center;
    }

    .imgsize {
        width: 80%;
        margin: 0 auto;
    }

    .newstitle {
        font-size: 36px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .newscont {
        font-size: 16px;
        line-height: 30px;
        color: #666;
        text-indent: 28px;
    }

    .fonttitle {
        font-weight: bold;
        text-indent: 28px;
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .posttexttype {
        font-size: 14px;
        color: #999;
    }

    .bottomline {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: ''
            }
        },
        methods: {
            nextperson() {
                window.location.href = "/newsdetail2";
            },
        },
    }
</script>