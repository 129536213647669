<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url5" :preview-src-list="srcList5">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">覆冰拉力在线监测装置</div>
                    <div class="detaildesr">
                        覆冰在线监测系统是针对于在恶劣大气环境中运行的高压输电线路覆冰 (雪) 状态进行在线监测而设计的监测装置。适合输电线路重冰区部分区段线路
                        和迎风山坡、垭口、风道、大水面附近等易覆冰特殊地理环境区，也适合安装在与冬季主导风向夹角大于 45°的线路易覆冰舞动区。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    覆冰在线监测系统是针对于在恶劣大气环境中运行的高压输电线路覆冰 (雪) 状态进行在线监测而设计的监测装置。适合输电线路重冰区部分区段线路
                    和迎风山坡、垭口、风道、大水面附近等易覆冰特殊地理环境区，也适合安装在与冬季主导风向夹角大于 45°的线路易覆冰舞动区。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    前端采集传感器进行拉力倾角测量，根据自带的覆冰计算模型，可直接输出覆冰厚度。前端装置实时监测综合悬挂载荷、不均衡张力差、绝缘子串风偏角、
                    绝缘子串偏斜角等数据信息，并通过4/5G或北斗通讯网络将数据传送致后端监控中心，监控中心通过平台软件对线路覆冰厚度的数据实时分析。
                </div>
            </el-tab-pane>
            <el-tab-pane label="参数" name="third">
                <el-descriptions class="margin-top" title="" :column="1" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            参数性能
                        </template>
                        量程：7～55t（可按实际需要定制）；测量范围：5％～100％FS（线性工作区间）
                        破坏荷载：大于标称荷载的1.2倍；准确度级别（FS）：0.2级以上
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            安装位置
                        </template>
                        采用17－4PH高强度不锈钢材料，安装于杆塔结构与绝缘李子串之间
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            供电方式
                        </template>
                        光能+磷酸铁锂电池+超级电容供电； 具备远端电源管理功能；具备外置电源接口
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            抗干扰能力
                        </template>
                        满足输电线路环境下的强电场、强磁场的恶劣工作环境要求
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            数据采集
                        </template>
                        实时监测综合悬挂载荷、不均衡张力差
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            数据通信
                        </template>
                        采用4/5G或北斗通信模块，电力专网自适应要求，关键数据采用算法加密后再传输
                    </el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url5: 'http://home.hzhjny.com/var/file/product15.png',
                srcList5: [
                    'http://home.hzhjny.com/var/file/product15.png',
                ],



                activeNames: 'first',
            }
        },
        methods: {
            newsdetail() {
                window.location.href = '/newsdetail'
            },

            newsdetail1() {
                window.location.href = '/newsdetail1'
            },

            newsdetail2() {
                window.location.href = '/newsdetail2'
            }
        },
    }
</script>