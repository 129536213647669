<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url7" :preview-src-list="srcList7">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">声纹成像监测系统</div>
                    <div class="detaildesr">
                        声纹成像监测系统基于声学成像定位算法和声纹指纹特征分析的电气设备绝缘定位与振动监测联合诊断算法，
                        是一种新的基于声音信号的监测方案，能够更有效地加强对于变压器绝缘缺陷、异常振动状态的监测，提升设备可靠性,加强设备全寿命周期管理工作，降低电网运行潜在风险。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    声纹成像监测系统基于声学成像定位算法和声纹指纹特征分析的电气设备绝缘定位与振动监测联合诊断算法，
                    是一种新的基于声音信号的监测方案，能够更有效地加强对于变压器绝缘缺陷、异常振动状态的监测，提升设备可靠性,加强设备全寿命周期管理工作，降低电网运行潜在风险。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    声纹成像技术具有非接触、测试便捷、结果准确直观等优点,非接触式测量具有很大发展空间，能有效的监测和诊断电力设备的局放和异响缺陷。
                    基于深度学习神经网络的声纹系统将不断学习新的故障声音及振动，逐步形成更大的故障库目前系统支持对 28 种故障声音及振动进行精确的识别。
                </div>
            </el-tab-pane>
            <el-tab-pane label="参数" name="third">
                <el-descriptions class="margin-top" title="" :column="2" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            麦克风通道数
                        </template>
                        128 通道
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            测试频率范围
                        </template>
                        2kH-48kHz
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            摄像头分辨率
                        </template>
                        <el-tag size="small">800W</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            帧率
                        </template>
                        25FPS
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            测试距离
                        </template>
                        0.5-50米
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            IP 防护等级
                        </template>
                        IP56
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            供电方式
                        </template>
                        磷酸铁锂电池+超级电容电源供电
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            工作方式
                        </template>
                        对采集到的声纹数据进行整合并进行识别
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">
                            抗干扰能力
                        </template>
                        满足变电站环境下的强电场、强磁场的恶劣工作环境要求
                    </el-descriptions-item>

                </el-descriptions>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url7: 'http://home.hzhjny.com/var/file/product17.png',
                srcList7: [
                    'http://home.hzhjny.com/var/file/product17.png',
                ],


                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>