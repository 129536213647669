<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>


  // import HelloWorld from "./components/HelloWorld.vue";
  // import Home from "./components/Home.vue";
  export default {
    name: "App",


    mounted() {
      const computed = function () {
        // 设计稿宽度：1920
        const desW = 1920
        const devW = document.documentElement.clientWidth
        if (devW > 1280) {
          document.documentElement.style.fontSize = devW / desW * 16 + 'px'
          // 这里的75是使用postcss-px2rem插件时，配置的remUnit的参数值
        } else {
          // document.documentElement.style.fontSize = 16 + 'px'
          document.documentElement.style.fontSize = devW / desW * 20 + 'px'
        }

      }
      computed()
      window.addEventListener('resize', computed, false)
    }

  };
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  body {
    margin: 0;
    background: #f5f5f5;
  }

  a {
    text-decoration: none;
    color: #2c3e50;
  }
</style>