import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import './style/main.css'
// import store from "./store";

//引入VueRouter
import VueRouter from 'vue-router'
// import validator from './util/validator'
// import Pagination from "@/components/Pagination";
// import JSEncrypt from 'jsencrypt'
//引入路由器
import router from './router'
import moment from 'moment'
// Vue.component('Pagination', Pagination)
Vue.use(VueRouter)
Vue.use(ElementUI);
Vue.config.productionTip = false
// Vue.prototype.$validator = validator
Vue.prototype.$moment = moment
// Vue.prototype.$JSEncrypt = JSEncrypt
// new Vue({
//   render: h => h(App),
//   router: router
// }).$mount('#app')

new Vue({
  el: '#app',
  render: h => h(App),
  router,
  // store,
})
