<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url5" :preview-src-list="srcList5">
                    </el-image>
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">防山火红外监测装置</div>
                    <div class="detaildesr">
                        防山火在线监测装置是由主控单元、视频监测单元、红外双光谱山火监测单元、激光照明器、通信单元、供电单元、安装支架及辅材组成；通过红外双光谱山火监测单元实时自动巡航探测目标环境的热源，对山火发生点精确定位，并具备把山火发生点的具体地点用无线4G／5G网络的方式发送给电力部门的功能，指挥中心可以在装置的后台软件、
                        移动终端等进行直观云台遥调视频显示观看山火发生点，同时可利用GIS地理信息系统的路径规划，以最短路径指挥扑救队员赶往现场，完成扑救工作。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    防山火在线监测装置是由主控单元、视频监测单元、红外双光谱山火监测单元、激光照明器、通信单元、供电单元、安装支架及辅材组成；通过红外双光谱山火监测单元实时自动巡航探测目标环境的热源，对山火发生点精确定位，并具备把山火发生点的具体地点用无线4G／5G网络的方式发送给电力部门的功能，指挥中心可以在装置的后台软件、
                    移动终端等进行直观云台遥调视频显示观看山火发生点，同时可利用GIS地理信息系统的路径规划，以最短路径指挥扑救队员赶往现场，完成扑救工作。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    输电线路林区火灾具有季节性、突发性、蔓延速度快、短期内可造成重大经济损失等特点。因此缩短火灾侦察时间、迅速发现和扑灭林火，防止火势蔓延及失控就成为了输电线路林区防火的重中之重。红外双光谱防山火在线监测装置是最直观最有效的电网山火监控方案，实时探测、可视化监控区域内的可燃物、烟雾、明火火情，
                    防患于“未燃”，真正做到全天候24小时有效监控，保障线路的安全稳定运行。
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url5: 'http://home.hzhjny.com/var/file/product40.png',
                srcList5: [
                    'http://home.hzhjny.com/var/file/product40.png',
                ],



                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>