<template>
    <div class="backmain">
        <div>
            <img src="http://home.hzhjny.com/var/file/newsback.jpg" alt="" class="bannerimg">
        </div>
        <div class="content">
            <div class="newstitle">
                国网浙江电力：技术标准推动新型电力系统省级示范区建设
            </div>
            <!-- <div class="imgmain">
                <img src="http://home.hzhjny.com/var/file/product7.jpg" alt="" class="imgsize">
            </div> -->
            <div style="text-align: center;color: #999;font-size: 14px;">
                2023-10-13 17:25 来源： 浙江在线
            </div>
            <hr>
            <div class="newscont">
                <span style="color: #333;font-weight: bold;">浙江在线10月13日讯（通讯员 徐梓沐 李乃一）</span>
                10月14日是世界标准日。技术标准作为协调统一技术事项的重要基础性文件，是支撑企业高质量发展的重要技术力量。近年来，
                国网浙江电力聚焦新型电力系统省域示范标准化建设，加强国际标准孵化和创制，高质量发展步履铿锵。<br>
            </div>
            <div class="fonttitle">
                推动技术标准“走出去”
            </div>
            <div class="newscont">
                2022年11月，国网浙江电力发布IEEE标准《电力领域知识图谱应用指南》，作为电力知识图谱领域首个国际标准，它定义了不同企业构建的电力知识图谱之间的兼容和协作规范，为电力行业形成更加完整和准确的知识服务生态系统、实现可持续发展提供坚实基础。这是国网浙江电力技术标准化工作取得的又一项标志性成果。
            </div>
            <div class="newscont">
                经多年沉淀，在技术标准交流发展中，国网浙江电力“走出去”成效显著。2022年，由该公司主导或联合主导发布发布电动汽车充电相关1项IEC标准，电力金具、工器具、知识图谱相关3项IEEE标准相继发布。2023年，立项国内首个虚拟电厂领域ITU标准《基于区块链的虚拟电厂运营平台参考架构》以及智慧城市、智能巡检、电工材料等领域共5项国际标准。凭借一套套中国方案，国网浙江电力国际话语权和影响力不断提升，支持电力领域高质量发展。
            </div>
            <div class="newscont">
                2022年9月，国网浙江电力下属浙江华电研究院获浙江省市场监督管理局批复，筹建浙江省新型电力系统建设标准化技术委员会，并承担秘书处工作；2023年4月，国网浙江电科院完成ISO标准《塑料-离子交换树脂-粒度检测
                激光衍射法》立项，成为国家电网公司首家实现IEC、ISO、ITU三大国际标准全覆盖的省级电科院；2022年以来，国网台州、嘉兴供电公司先后顺利通过中电联组织的标准化良好行为企业创建或复审，获5A级评价……
            </div>
            <div class="newscont">
                良好的发展生态保障了技术标准工作多点开花。近年来，国网浙江电力依托标准化研究所专业团队，建立专家帮扶机制，摸排标准化发展需求，举办了多场主题讲座、技术标准宣贯培训。成立国际标准创制工作组，为标准推进提供技术辅导、经验支持、资源配给以及沟通攻关等帮助。这使得基层创制能力得以有效释放：2022年至今，国网浙江电力所属8家地市公司、直属单位牵头立项了14项国际标准，覆盖ISO、IEC、ITU、IEEE四类。
            </div>
            <div class="fonttitle">
                建立完善技术标准体系
            </div>
            <div class="newscont">
                10月10日，国网金华供电公司运维检修部对220千伏孟湖变综合检修现场开展专项督导，旁听现场作业交底，检查“两票”执行、作业风险防范等工作落实情况，并对主变压器、组合电器等6类主设备的检修标准，技术监督、反措要求落实情况开展督导，以保障设备管理标准规范落实落地，提升作业现场安全水平。
            </div>
            <div class="newscont">
                执行是发挥技术标准价值作用的关键一环。国网浙江电力结合春季、秋季安全大检查和技术监督等工作，将标准执行情况的监督检查与日常业务管理工作有机衔接，要求及时记录并整改技术标准在执行过程中发现的违反和不符合标准问题。
            </div>
            <div class="newscont">
                据国网浙江电力科技部主任周自强介绍，今年，该公司按照“管专业必须管标准”的原则，建立健全业务部门负责人“挂帅”组织体系和运行机制，并构建技术标准监督考核体系，将标准实施监督评价工作与业务活动和企业管理等有机融合，通过发挥技术标准专业工作组的指导作用，保障业务工作质量与效率双提升。
            </div>
            <div class="newscont">
                提升标准创制能力同样离不开人才培育。2023年9月，浙江华电研究院李瑞获IEEE标准协会表彰，肯定其对电力金具和导体的能效测试和评估标准的发展作出的贡献。2022年，通过自主申报、统一遴选，共计推荐6名人员报名并全部入选中国国际标准青年英才暨IEC青年专家选培活动。
            </div>
            <div class="newscont">
                周自强介绍，加快科研人才培养，实现科技创新、标准创制、人才培养的全面协同，该公司形成了“科研活动产标准、各级单位用标准、专家人才出标准”的良好局面。未来，国网浙江电力还将发挥国家绿色技术交易中心作用，推动重大科技成果项标准转换，带动双碳目标下的电力领域业务转型和提质增效。
            </div>

            <div class="fonttitle">
                为新型电力系统建设注入活力
            </div>

            <div class="newscont">
                当下，绿色是发展的主色调，聚焦于此，国网浙江电力技术标准支撑更加有力。2022年以来，该公司建立健全碳达峰、碳中和标准，实施碳达峰、碳中和标准化提升工程，开展“基于新能源云平台的碳中和支撑服务”新型电力系统示范项目建设，致力于推动能源领域保供稳价和绿色转型。
            </div>

            <div class="newscont">
                为了更好发挥标准实效、更大范围扩大标准的影响力，国网浙江电力联动政府部门，推动标准落地。先后联合浙江省经信厅、统计局制定并发布《浙江省工业企业碳效综合评价暨碳效码编码细则》，会同湖州市政府碳主管部门编制发布《工业企业碳效评价规范》地方标准。依据标准建设成果，浙江率先组织实施企业碳效智能对标和分类管理，有力服务工业领域绿色低碳发展。
            </div>

            <div class="newscont">
                2023年2月，在国网浙江电力的推动下我国虚拟电厂领域首批2项国家标准之一《虚拟电厂管理规范》成功立项。实现高质量发展，国网浙江电力积极推进技术标准工作，厘清已有标准，对照能源领域发展需求，加大业务发展重点领域技术标准支撑力度。
            </div>
            <div class="newscont">
                2018年《国家标准化发展纲要》发布，国网浙江电力结合电力基础设施建设、新型电力系统建设需求，对照纲要内容，系统梳理技术标准推进情况统计表。聚焦全省电力技术发展趋势，梳理电力建设相关标准70余项，搭建起浙江省电力建设标准化体系框架；梳理电力物资相关标准40余项，建立电力企业物资供应链统一的标准体系，为国家电网公司绿色现代数智供应链建设提供支撑；梳理涵盖低频输电、光伏储能、新能源调度、碳资产所属新型电力系统领域相关标准140项，为新型电力系统省级示范区建设工作提供坚实基础和保障。
            </div>
            <hr>
            <div class="bottomline">
                <div class="posttexttype" style="cursor: pointer;" @click="lastperson">
                    上一个：中国电力：创新引领未来能源
                </div>
                <div class="posttexttype">
                    下一个：无
                </div>
            </div>
        </div>

    </div>
</template>

<style scoped>
    .bannerimg {
        width: 100%;
    }

    .backmain {
        width: 100%;
        background: white;
    }

    .content {
        width: 70%;
        margin: 0 auto;
        margin-top: 20px;
        padding-bottom: 30px;
        background: white;
    }

    .imgmain {
        text-align: center;
    }

    .imgsize {
        width: 80%;
        margin: 0 auto;
    }

    .newstitle {
        font-size: 36px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .newscont {
        font-size: 16px;
        line-height: 30px;
        color: #666;
        text-indent: 28px;
    }

    .fonttitle {
        font-weight: bold;
        text-indent: 28px;
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .posttexttype {
        font-size: 14px;
        color: #999;
    }

    .bottomline {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: ''
            }
        },
        methods: {
            lastperson() {
                window.location.href = '/newsdetail2'
            }
        },
    }
</script>