<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url9" :preview-src-list="srcList9">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">激光除异物装置</div>
                    <div class="detaildesr">
                        电网激光除异物装置是一套基于地面定向发射能量的电网异物远程清除系统。该系统对高空异物进行远距离清除，实现带电、远程、非接触式作业，具有成本低、耗时短、安全可控、操作简单、可带电作业、
                        对导线无损伤等特点，适用于大部分架空线路非金属异物清除，省时省力，极大提升了线路维护的人员安全和经济效益。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    电网激光除异物装置是一套基于地面定向发射能量的电网异物远程清除系统。该系统对高空异物进行远距离清除，实现带电、远程、非接触式作业，具有成本低、耗时短、安全可控、操作简单、可带电作业、
                    对导线无损伤等特点，适用于大部分架空线路非金属异物清除，省时省力，极大提升了线路维护的人员安全和经济效益。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    电网激光除异物装置能从地面发射可控激光对高空异物进行远距离切割清除，实现带电远程非接触式作业，具有安全、快速、可带电作业的特点，适用于大部分架空线路非金属异物清除，克服了传统处理方法存在的不足，无需停电和人员攀爬杆塔，
                    可直接远距离及时、快速、安全地清除导线上的异物，操作方便、省时省力。瞄准系统，由高精密数控云台、电子望远镜式瞄准镜和可见指示光系统组成。
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url9: 'http://home.hzhjny.com/var/file/product30.png',
                srcList9: [
                    'http://home.hzhjny.com/var/file/product30.png',
                ],


                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>