<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url5" :preview-src-list="srcList5">
                    </el-image>
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">智能综合接地箱</div>
                    <div class="detaildesr">
                        高压电缆智能接地箱定义为一种综合监测电缆运行状态的设备，基于集成化一体设计，主要由箱体、传感器、信号模块、电缆运行状态监测箱及监测软件组成。其主要监测电缆护套环流、箱体环境量、等关键参数信息。由于电缆敷设环境影响，高压电缆智能接地箱主要分为直立式和壁挂式，分别应用与地表及地下通道。高压电缆智能接地箱通过先进的传感技术、测量技术、通信技术对电缆运行的状态实时监测，为电缆安全运行提供可靠保障，降低电缆事故率。
                        通过实时监测电缆的接地铠装环流、感应电压，及时发现电缆的接地问题；并且在有人偷盗高压电缆时，该系统可以通过后台管理软件及时报警通知相关人员处理事件。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    高压电缆智能接地箱定义为一种综合监测电缆运行状态的设备，基于集成化一体设计，主要由箱体、传感器、信号模块、电缆运行状态监测箱及监测软件组成。其主要监测电缆护套环流、箱体环境量、等关键参数信息。由于电缆敷设环境影响，高压电缆智能接地箱主要分为直立式和壁挂式，分别应用与地表及地下通道。高压电缆智能接地箱通过先进的传感技术、测量技术、通信技术对电缆运行的状态实时监测，为电缆安全运行提供可靠保障，降低电缆事故率。
                    通过实时监测电缆的接地铠装环流、感应电压，及时发现电缆的接地问题；并且在有人偷盗高压电缆时，该系统可以通过后台管理软件及时报警通知相关人员处理事件。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr" style="text-indent: 0px;">
                    ·实时监测，分级集中管理。具有统计，查询和打印各类表格的功能，提高维护和管理水平；<br>
                    ·及时报警，自动派障，能实时掌握系统所属各端局设备的维护作业等运行情况；<br>
                    ·确保受监控电缆的安全。电缆在超过门限值时能够及时的告警；<br>
                    ·取代传统的环流检测方式，由人工检查升级到电子科技设备测试，做到了实时在线监测；<br>
                    ·节省电力部门的运作成本。能有效的提高工作效。
                </div>
            </el-tab-pane>
            <el-tab-pane label="参数" name="third">
                <el-descriptions class="margin-top" title="" :column="2" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            电压等级
                        </template>
                        35kV-220kV
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            外壳材质
                        </template>
                        SMC不饱和树脂
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            工作温度
                        </template>
                        -55°C~+85°C
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            外壳防护等级
                        </template>
                        IP65
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions class="margin-top" title="" :column="1" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            连接方式
                        </template>
                        接地电缆、同轴电缆压板合接; 经护层保护器接地或直接接地。
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            技术性能
                        </template>
                        箱体经受直流20kV,1分钟不击穿，箱体经受冲击25kV,±10次不击穿
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            直流耐压试验
                        </template>
                        20kV，历时1min，不闪络，不击穿
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            冲击电压试验
                        </template>
                        峰值25kV，正负极性各10次，不闪络，不击穿
                    </el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url5: 'http://home.hzhjny.com/var/file/product45.png',
                srcList5: [
                    'http://home.hzhjny.com/var/file/product45.png',
                ],



                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>