<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url9" :preview-src-list="srcList9">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">无线温度传感器</div>
                    <div class="detaildesr">
                        无线测温系统是由测温传感器，数据读取器、数据接收天线和后台监控系统等组成。
                        主要用来测量带电物体表面温度.如对高压开关柜中的母排接头和裸露触电以及电缆接头处运行温度进行监测.无线温度传感器主要包括测量电路、温度传感器、无线调制接口、逻辑控制电路、供电电路。通过无线网络将温度信号传送到无线温度监测仪。
                        无线温度传感器由于体积比较小.适合用来测量温度热接点，可以对开关柜中引出电缆接头，触头、母线连接点的运行温度进行测量，也可以对地下电缆接头处进行测量。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    无线测温系统是由测温传感器，数据读取器、数据接收天线和后台监控系统等组成。
                    主要用来测量带电物体表面温度.如对高压开关柜中的母排接头和裸露触电以及电缆接头处运行温度进行监测.无线温度传感器主要包括测量电路、温度传感器、无线调制接口、逻辑控制电路、供电电路。通过无线网络将温度信号传送到无线温度监测仪。
                    无线温度传感器由于体积比较小.适合用来测量温度热接点，可以对开关柜中引出电缆接头，触头、母线连接点的运行温度进行测量，也可以对地下电缆接头处进行测量。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr" style="text-indent: 0px;">
                    ·安全性:体积小，等电位单点绝缘安装，不降低电设备的安全性能；<br>
                    ·可靠性:金属外壳设计，形成电屏蔽，在强电磁场下稳定工作；<br>
                    ·准确性:采用NTC高精度感温元件，测量精度达到土0.5 °C <br>
                    ·接触式测温，能快速准确地反映测温点温度变化；<br>
                    ·实时性:温度有变化即时发送，实时监测，快速反映。温度无变化，10 分钟发射一次；<br>
                    ·低功耗设计延长设备使用寿命。
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url9: 'http://home.hzhjny.com/var/file/product23.png',
                srcList9: [
                    'http://home.hzhjny.com/var/file/product23.png',
                ],


                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>