<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url5" :preview-src-list="srcList5">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">模拟导线覆冰监测装置</div>
                    <div class="detaildesr">
                        模拟导线覆冰监测是一和采用模拟导线实时测量线路覆冰的测量装置。相比于常见的需要线路停电安装，直接安装在导线或绝缘子串上的覆冰监测装置，工作量更小，成本更低。此装置采用模拟导线进行线路覆冰监测，其思路是在线路上悬挂一根与线路导线同型号、同材质的模拟导线，
                        以产生与线路相同气象条件下的覆冰情兄，通过测量模拟导线重量的变化来确定覆冰的厚度，从而等同推测输电线路导线的综合荷载和覆冰厚度。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    模拟导线覆冰监测是一和采用模拟导线实时测量线路覆冰的测量装置。相比于常见的需要线路停电安装，直接安装在导线或绝缘子串上的覆冰监测装置，工作量更小，成本更低。此装置采用模拟导线进行线路覆冰监测，其思路是在线路上悬挂一根与线路导线同型号、同材质的模拟导线，
                    以产生与线路相同气象条件下的覆冰情兄，通过测量模拟导线重量的变化来确定覆冰的厚度，从而等同推测输电线路导线的综合荷载和覆冰厚度。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr" style="text-indent: 0px;">
                    ·安装简单：设备安装方便简单，不涉及线路带电部分，无需停电安装；<br>
                    ·算法精准：采用模拟导线专用算法，更精准可靠；<br>
                    ·成本更低：相比传统覆冰监测装置，无需线路停电安装，直接安装在杆塔合适位置即可，人力物力成本更低；<br>
                    ·物联联动：可与微气象在线监测装置、微拍监测装置联动，全维度在线监测覆冰情况。
                </div>
            </el-tab-pane>
            <el-tab-pane label="参数" name="third">
                <el-descriptions class="margin-top" title="" :column="2" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            综合精度
                        </template>
                        0.02~0.05%F.S
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            零点输出
                        </template>
                        ±1%F.S
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            蠕变
                        </template>
                        ±0.02%F.S
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            零点温度影响
                        </template>
                        ±0.02%F.S/10℃
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            防护等级
                        </template>
                        IP68
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            输出温度影响
                        </template>
                        ±0.02%F.S/10℃
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions class="margin-top" title="" :column="1" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            传感器量程
                        </template>
                        10kg/20kg/50kg/100kg可选，默认10kg
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            采集处理
                        </template>
                        自动采集和受控采集，具备智能数据处理与判别，数据循环存储90天
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            供电时长
                        </template>
                        持续无光照可以维持装置正常运行15天的供电要求
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            供电方式
                        </template>
                        光能+磷酸铁锂电池+超级电容供电； 具备远端电源管理功能；具备外置电源接口
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            通讯性能
                        </template>
                        采用4/5G或北斗通信模块，电力专网自适应要求，关键数据采用算法加密后再传输
                    </el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url5: 'http://home.hzhjny.com/var/file/product36.png',
                srcList5: [
                    'http://home.hzhjny.com/var/file/product36.png',
                ],



                activeNames: 'first',
            }
        },
        methods: {
            newsdetail() {
                window.location.href = '/newsdetail'
            },

            newsdetail1() {
                window.location.href = '/newsdetail1'
            },

            newsdetail2() {
                window.location.href = '/newsdetail2'
            }
        },
    }
</script>