<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url10" :preview-src-list="srcList10">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">行波故障定位及预警装置</div>
                    <div class="detaildesr">
                        行波故障定位及预警装置是以采集各类波段、波形为基础，针对线路运行的技术特性和运行环境，实现了对线路故障预警、
                        分析判断及精准定位。在线路运行中实时监测线路运行情况，及时发现线路存在的安全隐患以及判断故障类型并精确定位；发现故障隐患、指导运维检修人员及时处理故障。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    行波故障定位及预警装置是以采集各类波段、波形为基础，针对线路运行的技术特性和运行环境，实现了对线路故障预警、
                    分析判断及精准定位。在线路运行中实时监测线路运行情况，及时发现线路存在的安全隐患以及判断故障类型并精确定位；发现故障隐患、指导运维检修人员及时处理故障。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    行波故障定位及预警装置，通过实时采集线路各时刻各监测点的行波电压、电流数据，合成零序电压、电流。然后终端设备自我进行广域的比幅、比相，通过判定实现故障点位的选线和故障定位的判别。避免采用经济效益低，人工成本高的全线试拉、试送的方式排查故障。
                </div>
            </el-tab-pane>
            <el-tab-pane label="参数" name="third">
                <el-descriptions class="margin-top" title="" :column="3" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            故障定位精度
                        </template>
                        ≤±150m
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            故障精确定位准确率
                        </template>
                        ≥90%
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            故障区间定位可靠性
                        </template>
                        ≥99%
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            启动电流
                        </template>
                        ≥3A
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            接地隐患监测准确率
                        </template>
                        ≥90%
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            绝缘隐患监测准确率
                        </template>
                        ≥90%
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions class="margin-top" title="" :column="1" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            安装位置
                        </template>
                        直接安装至配网线路导线上，可带电安装
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            供电方式
                        </template>
                        取能供电模块采用交流感应取电技术+锂电池+超级电容电源供电，长效稳定
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            抗干扰能力
                        </template>
                        装置能满足干扰环境下的强电场、强磁场的恶劣工作环境要求
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            数据通信
                        </template>
                        配置4G APN通信模块，关键数据采用算法加密后再传输。通信方式满足电力专网自适应要求
                    </el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                size: 'medium',
                textarea2: '',
                url10: 'http://home.hzhjny.com/var/file/product20.png',
                srcList10: [
                    'http://home.hzhjny.com/var/file/product20.png',
                ],

                activeNames: 'first',
            }
        },
        methods: {
            newsdetail() {
                window.location.href = '/newsdetail'
            },

            newsdetail1() {
                window.location.href = '/newsdetail1'
            },

            newsdetail2() {
                window.location.href = '/newsdetail2'
            }
        },
    }
</script>