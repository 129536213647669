<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 50%;margin:0 auto" :src="url" :preview-src-list="srcList">
                    </el-image>
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">微拍监测装置-枪机式</div>
                    <div class="detaildesr">
                        枪机式微拍监测装置是一款聚焦于解决输电线路杆塔双侧通道监控、塔基防外破及塔上设备互联为核心，集智能四摄、AI前置及超低功耗功能于一体的图像在线监控装置，支持定时自动抓拍和手动抓拍，支持20s短视频拍摄和传输。并具备AI识别功能，例如绝缘子、间隔棒
                        、防振锤等金具均可识别，也可对环境进行识别如塔吊, 挖掘机, 泵车等大型机械以及烟雾, 火点等安全隐患。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    枪机式微拍监测装置是一款聚焦于解决输电线路杆塔双侧通道监控、塔基防外破及塔上设备互联为核心，集智能四摄、AI前置及超低功耗功能于一体的图像在线监控装置，支持定时自动抓拍和手动抓拍，支持20s短视频拍摄和传输。并具备AI识别功能，例如绝缘子、间隔棒
                    、防振锤等金具均可识别，也可对环境进行识别如塔吊, 挖掘机, 泵车等大型机械以及烟雾, 火点等安全隐患。
                </div>
            </el-tab-pane>
            <el-tab-pane label="参数" name="third">
                <el-descriptions class="margin-top" title="" :column="1" :size="size" border>
                    <el-descriptions-item>
                        <template slot="label">
                            摄像性能
                        </template>
                        具备夜视功能的单体摄像头一体机， <el-tag size="small">800w</el-tag>像素
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            云台功能
                        </template>
                        固定式枪机，无云台功能
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            安装位置
                        </template>
                        安装于杆塔上。外壳与结构件由304不锈钢材质或铸铁材质构成，适用角钢塔、砼杆、钢管塔等不同杆塔
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            供电方式
                        </template>
                        光能+磷酸铁锂电池+超级电容电源供电； 具备远端电源管理功能；具备外置电源接口
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            抗干扰能力
                        </template>
                        满足输电线路环境下的强电场、强磁场的恶劣工作环境要求
                    </el-descriptions-item>
                    <el-descriptions-item>
                        <template slot="label">
                            数据通信
                        </template>
                        配置4G APN通信模块，关键数据应采用算法加密后再传输。满足电力专网自适应要求
                    </el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                size: 'medium',
                input1: '',
                input2: "",
                textarea2: '',
                url: 'http://home.hzhjny.com/var/file/product10.png',
                srcList: [
                    'http://home.hzhjny.com/var/file/product10.png',
                ],
                activeNames: 'first',
            }
        },
        methods: {
            newsdetail() {
                window.location.href = '/newsdetail'
            },

            newsdetail1() {
                window.location.href = '/newsdetail1'
            },

            newsdetail2() {
                window.location.href = '/newsdetail2'
            }
        },
    }
</script>