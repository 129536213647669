<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url5" :preview-src-list="srcList5">
                    </el-image>
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">智能激光语音综合驱鸟装置</div>
                    <div class="detaildesr">
                        本驱鸟器集激光驱鸟技术和语音驱鸟技术于一体，首先本装置驱鸟是用进口原件制造的一种激光驱鸟器系统，由它发出的532nm激光刺激鸟类，使鸟类远离，保证驱鸟效果的持久性。
                        其次本装置利用数字语音技术，随机、间隔式输出播放各种令鸟类害怕的声音，使鸟儿感到此地危险重重，从而远离，有效防止了鸟类的适应性，从而达到长期驱鸟目的。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    本驱鸟器集激光驱鸟技术和语音驱鸟技术于一体，首先本装置驱鸟是用进口原件制造的一种激光驱鸟器系统，由它发出的532nm激光刺激鸟类，使鸟类远离，保证驱鸟效果的持久性。
                    其次本装置利用数字语音技术，随机、间隔式输出播放各种令鸟类害怕的声音，使鸟儿感到此地危险重重，从而远离，有效防止了鸟类的适应性，从而达到长期驱鸟目的。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    双能量刺激性驱鸟，采用激光、语音、超声波等多重能量进行刺激性驱鸟，相比传统的恐吓性驱鸟，驱鸟效果更明显、更持久，对麻雀、灰喜鹊、珠颈斑鸠等有很好的驱赶效果。
                    F区域净空效果明显，打破鸟类适应性，激光驱鸟长期有效，本驱鸟装置能有效驱赶防区内98％以上的鸟类。
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url5: 'http://home.hzhjny.com/var/file/product41.png',
                srcList5: [
                    'http://home.hzhjny.com/var/file/product41.png',
                ],



                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>