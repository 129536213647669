<template>
    <div class="backmain">
        <div>
            <el-row>
                <el-col :span="12" style="padding-right: 20px;text-align: center;">
                    <el-image style="width: 70%;margin:0 auto" :src="url9" :preview-src-list="srcList9">
                    </el-image>
                    <!-- <img src="http://home.hzhjny.com/var/file/product15.png" alt="" class="imgsize"> -->
                </el-col>
                <el-col :span="12" style="padding-right: 20px;">
                    <div class="detailtitle">电力智能井盖</div>
                    <div class="detaildesr">
                        电力智能井盖可对沟内温湿度环境、水位、有害气体等实现无人实时监控，实现电缆沟智能无人监控，
                        减轻工作人员的劳动强度，提高了安全性，为监控数据预防性维护提供依据。配备防异常移动传感器，保证财产安全。可定制井盖以应对不同尺寸需求。
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeNames">
            <el-tab-pane label="产品描述" name="first">
                <div class="productdesr">
                    电力智能井盖可对沟内温湿度环境、水位、有害气体等实现无人实时监控，实现电缆沟智能无人监控，
                    减轻工作人员的劳动强度，提高了安全性，为监控数据预防性维护提供依据。配备防异常移动传感器，保证财产安全。可定制井盖以应对不同尺寸需求。
                </div>
            </el-tab-pane>
            <el-tab-pane label="产品特色" name="second">
                <div class="productdesr">
                    采用GPRS物联网模块与云服务器进行连接，将井内监测系 统的数据发给数据中心进行处理；包含温度，湿度，有害气体，井盖倾斜振动等传感器，
                    还可根据需求集成定制电缆测温以及局放等传感器；根据配置采用一体化或外置集成光伏板对储能锂电池充电，后期维护成本小；可根据监测数据AI识别智能实时预警。
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped>
    .backmain {
        width: 100%;
        background: white;
        padding-left: 20px;
    }


    .detailtitle {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .detaildesr {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    .productdesr {
        font-size: 14px;
        text-indent: 28px;
        color: #666;
        line-height: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: '',
                size: 'medium',
                url9: 'http://home.hzhjny.com/var/file/product19.png',
                srcList9: [
                    'http://home.hzhjny.com/var/file/product19.png',
                ],


                activeNames: 'first',
            }
        },
        methods: {

        },
    }
</script>